import React from "react";
import { Route, Routes } from "react-router-dom";

import ChatsDetail from "../dashboard/chats/detail/ChatsDetail";

export default function StandaloneWidget(): React.JSX.Element {
  /**
   * This is the standalone widget (inner part)
   * that can be added to any (customer) website.
   */

  return (
    <div
      className={"flex-fill"}
      // To make flexbox and truncated text play nice together
      // (https://css-tricks.com/flexbox-truncated-text/).
      style={{ minWidth: 0 }}
    >
      <div className={"d-flex flex-column h-100"}>
        <div className={"d-flex h-100"}>
          <div
            className={"pt-1 pb-3 px-3 bg-white flex-grow-1"}
            // To make flexbox and truncated text play nice together
            // (https://css-tricks.com/flexbox-truncated-text/).
            style={{ minWidth: 0 }}
          >
            <Routes>
              <Route path="*" element={<ChatsDetail />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
