import axios, { AxiosError, AxiosInstance } from "axios";

import config from "../../../config";
import { Auth } from "./Auth";
import { Api } from "./Api";
import { removeUser, setUser } from "../../../features/userSlice";
import store from "../../../store";
import {
  removeOrganization,
  setOrganization,
} from "../../../features/organizationSlice";
import { setOrganizationApiKey } from "../../../features/organizationApiKeySlice";

const baseUrl: string = config.backendBaseUrl;
export const baseApiUrl: string = `${baseUrl}/api/v1/`;
const baseUserManagementUrl: string = `${baseUrl}/user-management/`;

// This is Django's default CSRF cookie name
// (see https://docs.djangoproject.com/en/5.1/ref/settings/#csrf-cookie-name).
const csrfCookieName: string = "csrftoken";
// This is Django's default session cookie name
// (see https://docs.djangoproject.com/en/5.1/ref/settings/#session-cookie-name).
const sessionCookieName: string = "sessionid";
// This is Django's default CSRF header name
// (see https://docs.djangoproject.com/en/5.1/ref/settings/#csrf-header-name).
const csrfHeaderName: string = "X-CSRFTOKEN";
// Keep in sync with `backend.api.views.base.OrganizationKeyAuthentication`.
const organizationApiKeyHeaderName: string = "X-Organization-Key";

enum ApiErrorMessages {
  Generic = "Sorry, er is iets fout gegaan.",
  Unauthorized = "De inloggegevens lijken niet te kloppen. Probeer het opnieuw.",
  NoConnection = "Sorry, er lijkt geen verbinding te zijn.",
  BadRequest = "De gegevens lijken niet te kloppen. Probeer het opnieuw.",
}

export const apiErrorGeneric: IApiError = {
  errorMessage: ApiErrorMessages.Generic,
};
const apiErrorUnauthorized: IApiError = {
  errorMessage: ApiErrorMessages.Unauthorized,
};

export const apiErrorBadRequest: IApiError = {
  errorMessage: ApiErrorMessages.BadRequest,
};

const axiosConfiguration = {
  baseURL: baseApiUrl,
  xsrfHeaderName: csrfHeaderName,
  xsrfCookieName: csrfCookieName,
  withCredentials: true,
};

const getCsrfToken = async (): Promise<string> => {
  /**
   * Get a CSRF token from cookie or server.
   */
  // Check if a CSRF token is already present in a cookie.
  let csrfToken: string | undefined = document.cookie
    ?.split("; ")
    .find((_cookie) => _cookie.startsWith(`${csrfCookieName}=`))
    ?.split("=")[1];
  // If CSRF token is not present in a cookie, request one from the server.
  if (!csrfToken) {
    await axios.get(`${baseUrl}/csrf/`, axiosConfiguration).then((response) => {
      csrfToken = response.data?.csrfToken;
    });
  }
  // Return the CSRF token.
  return csrfToken ? csrfToken : "";
};

export const getAxiosInstance = async (
  isFileUpload: boolean = false,
  isUserManagementRelated: boolean = false,
): Promise<AxiosInstance> => {
  /**
   * Get an Axios instance with the correct configuration.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: { [key: string]: any } = {
    "Content-Type": isFileUpload ? "multipart/form-data" : "application/json",
  };
  // Add the CSRF header.
  await getCsrfToken().then((csrfToken) => {
    headers[csrfHeaderName] = csrfToken;
  });
  // Add the organization API key header, if key is present.
  await getOrganizationApiKeyFromRedux().then(
    (organizationApiKey: string | null) => {
      if (organizationApiKey!) {
        // Set the organization API key header.
        headers[organizationApiKeyHeaderName] = organizationApiKey;
        // Unset the session cookie header, if any, to prevent session-based authentication.
        delete headers[sessionCookieName];
      }
    },
  );
  const axiosConfiguration = {
    baseURL: isUserManagementRelated ? baseUserManagementUrl : baseApiUrl,
    headers: headers,
    xsrfHeaderName: csrfHeaderName,
    xsrfCookieName: csrfCookieName,
    withCredentials: true,
  };
  return axios.create(axiosConfiguration);
};

export const handleAxiosError = async (
  error: AxiosError,
): Promise<IApiError> => {
  /**
   * Handling an Axios error.
   */
  // Handle server-returned errors.
  if (error?.response?.status) {
    if ([401, 409].includes(error.response.status)) {
      // Unauthorized, so logout.
      await Auth.logout().then(() => {
        return Promise.reject(apiErrorUnauthorized);
      });
    } else if (error.response.status === 400) {
      // Bad request, so return error data (e.g. form validation errors).
      const apiError: IApiError = {
        errorMessage: ApiErrorMessages.BadRequest,
        errorData: error.response?.data,
      };
      return Promise.reject(apiError);
    } else {
      // Any other (internal server or unexpected) errors.
      return Promise.reject(apiErrorGeneric);
    }
  }
  // Handle network errors.
  else if (error?.code === AxiosError.ERR_NETWORK) {
    // This could also mean that our server is down,
    // but more likely it's a network error on the user's side.
    const apiError: IApiError = {
      errorMessage: ApiErrorMessages.NoConnection,
    };
    return Promise.reject(apiError);
  }
  // Any other (unexpected) errors.
  return Promise.reject(apiErrorGeneric);
};

export const setUserInRedux = async (user: IUser): Promise<null> => {
  if (user) {
    // Set user in redux.
    store.dispatch(setUser(user));
  }
  return null;
};

export const deleteUserFromRedux = async (): Promise<null> => {
  store.dispatch(removeUser());
  return null;
};

export const getUserFromRedux = async (): Promise<IUser | null> => {
  return store.getState().user.value;
};

export const setOrganizationInRedux = async (
  organization: IOrganization,
): Promise<null> => {
  if (organization) {
    // Set organization in redux.
    store.dispatch(setOrganization(organization));
  }
  return null;
};

export const deleteOrganizationFromRedux = async (): Promise<null> => {
  store.dispatch(removeOrganization());
  return null;
};

export const getOrganizationFromRedux =
  async (): Promise<IOrganization | null> => {
    return store.getState().organization.value;
  };

export const setOrganizationApiKeyInRedux = async (
  organization_api_key: string,
): Promise<null> => {
  if (organization_api_key) {
    store.dispatch(setOrganizationApiKey(organization_api_key));
  }
  return null;
};

export const getOrganizationApiKeyFromRedux = async (): Promise<
  string | null
> => {
  return store.getState().organizationApiKey.value;
};

export const handleDownload = async (
  documentData: IDocument,
  documentBlobUrl: string,
) => {
  // FIXME (Rob): Handle this neater.
  const link = document.createElement("a");
  link.href = documentBlobUrl;
  link.download = documentData.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDocumentBlobUrl = async (
  document: IDocument,
): Promise<string> => {
  /**
   * Generate and return a document blob URL, for use with the document viewer and download components.
   */
  // To prevent caching issues, append (unused) timestamp query parameter.
  const documentDownloadUrl: string = `${config.backendBaseUrl}${document.url}?timestamp=${new Date().getTime()}`;
  // Set the document blob URL.
  return await Api.get(documentDownloadUrl, { responseType: "blob" }).then(
    // @ts-expect-error: Type { [key: string]: any; } | IApiError is not assignable to type Blob.
    (documentBlob: Blob) => {
      return URL.createObjectURL(documentBlob);
    },
  );
};

export const isApiError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: IApiError | { [key: string]: any },
): obj is IApiError => {
  return (obj as IApiError).errorMessage !== undefined;
};
