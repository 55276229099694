import React, { FormEvent, useEffect, useRef } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { Send } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorAlert from "../../../../common/ErrorAlert";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  getOrganizationFromRedux,
  getOrganizationApiKeyFromRedux,
} from "../../../../common/api/Utils";
import { NoDocuments } from "../../../../common/NoDocuments";
import {
  createNewChat,
  getAssistantGreeting,
  initializeChatMessages,
  handleMessageSendInner,
  organizationHasDocuments,
  scrollToBottomOfChatWindow,
} from "./Utils";
import { RecordComponent } from "./components/RecordComponent";
import { QuestionComponent } from "./components/QuestionComponent";
import { AnswerComponent } from "./components/AnswerComponent";
import { NewChatComponent } from "./components/NewChatComponent";
import { BreadcrumbComponent } from "./components/BreadcrumbComponent";
import { deserializeChat } from "../../../../common/Utils";

export default function ChatsDetail(props: {
  documentSpecificChat?: IChat | null;
}): React.JSX.Element {
  /**
   * Chat detail component.
   *
   * The chatData can be populated in three ways:
   * 1. From the location state (when a user navigates to a chat via chat-list).
   * 2. From the parameter props (when a user uses the document-specific chat widget).
   * 3. By creating a new chat (when a user uses the standalone widget).
   */
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const inputFieldRef = useRef<HTMLTextAreaElement>(null);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);
  const maxMessageLength: number = 1000;

  // Organization specific states.
  const [organizationData, setOrganizationData] =
    React.useState<IOrganization | null>(null);
  const [hasDocuments, setHasDocuments] = React.useState<boolean>(false);
  // Chat data specific states.
  const [chatData, setChatData] = React.useState<IChat | null>(
    location?.state?.chat || props?.documentSpecificChat || null,
  );
  const [messagesData, setMessagesData] = React.useState<
    Array<IQuestion | IAnswer>
  >([]);
  // Chat status specific states.
  const [message, setMessage] = React.useState<string>("");
  // This is like a lock...
  const [playingMessage, setPlayingMessage] = React.useState<IAnswer | null>(
    null,
  );
  const [isStandaloneWidget, setIsStandaloneWidget] =
    React.useState<boolean>(true);
  const isDocumentWidget: boolean = !!props?.documentSpecificChat;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isAnswering, setIsAnswering] = React.useState<boolean>(false);
  const [apiError, setApiError] = React.useState<IApiError | null>(null);

  const resetChatStates = () => {
    /**
     * Reset the chat states to their initial values.
     */
    setChatData(null);
    setMessagesData([]);
    setMessage("");
    setIsAnswering(false);
    setIsLoading(true);
    setApiError(null);
  };

  useEffect(() => {
    /**
     * Initialize organization specific states:
     * 1. Set widget state.
     * 2. Check that the organization has documents.
     * 3. Retrieve the organization data.
     */
    getOrganizationApiKeyFromRedux().then((apiKey: string | null) => {
      setIsStandaloneWidget(!!apiKey);
    });
    organizationHasDocuments().then(async (_hasDocuments: boolean) => {
      setHasDocuments(_hasDocuments);
      if (_hasDocuments) {
        // TODO (Rob): The organization won't be in redux if used in the widget
        //  (make API call or remove any organization logic from widget version?).
        await getOrganizationFromRedux().then(
          (organization: IOrganization | null) => {
            setOrganizationData(organization);
          },
        );
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    /**
     * Initialize chat specific states:
     * 1. Reset the chat states to their initial values.
     * 2. Set chat data (either from state, props, or by creating a new chat).
     */
    if (hasDocuments) {
      resetChatStates();
      if (location?.state?.chat?.id) {
        // Chat data present in state; use it.
        // This is the case when a user navigates to a chat.
        setChatData(location.state.chat);
      } else if (props?.documentSpecificChat?.id) {
        // Chat data present in props; use it.
        // This is the case when a user navigates to a document-specific chat.
        setChatData(props.documentSpecificChat);
      } else {
        // Chat data not present in state; create a new chat.
        // This is the case when a user uses the standalone widget.
        createNewChat(setApiError, dispatch).then(
          (_chatData: ISerializableChat | null) => {
            setChatData(_chatData ? deserializeChat(_chatData) : null);
          },
        );
      }
    }
  }, [location, hasDocuments]);

  useEffect(() => {
    /**
     * Initialize chat messages (questions and answers).
     * Only initialize if the chat ID is in sync with the state's chat ID, if any.
     */
    if (
      hasDocuments &&
      chatData?.id &&
      (!location?.state?.chat?.id || location.state.chat.id === chatData.id)
    ) {
      initializeChatMessages(chatData.id, setMessagesData, setApiError).then(
        () => {
          setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => {
              scrollToBottomOfChatWindow();
            }, 1);
          }, 0);
        },
      );
    }
  }, [location, hasDocuments, chatData?.id]);

  useEffect(() => {
    /**
     * Focus on the input field, when chat has been loaded and answer has been generated.
     */
    if (hasDocuments && !isLoading && !isAnswering && !apiError) {
      inputFieldRef?.current?.focus();
    }
  }, [location, hasDocuments, isLoading, isAnswering, apiError]);

  const handleMessageSend = async (e: FormEvent): Promise<void> => {
    /**
     * Handle sending a message to Manual.
     */
    e.preventDefault();
    await handleMessageSendInner(
      message,
      setIsAnswering,
      setMessagesData,
      setMessage,
      chatData!,
      setChatData,
      setApiError,
      dispatch,
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      {!isStandaloneWidget && !isDocumentWidget ? (
        <BreadcrumbComponent
          topic={chatData?.topic || null}
          navigate={navigate}
        />
      ) : (
        <></>
      )}

      <div
        className="h-100 rounded-3 d-flex flex-column justify-content-between"
        style={{ minHeight: "15rem" }}
      >
        {isLoading ? (
          <div
            className={"h-100 d-flex justify-content-center align-items-center"}
          >
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : apiError ? (
          <ErrorAlert apiError={apiError} />
        ) : !hasDocuments && !isLoading ? (
          <>
            <NoDocuments />
          </>
        ) : (
          <>
            <div
              id={"chatWindow"}
              className={"flex-grow-1 d-flex flex-column overflow-auto"}
              style={{
                height: 0,
                marginRight: "-1rem",
                paddingRight: "1rem",
              }}
            >
              <AnswerComponent
                answer={{
                  message: getAssistantGreeting(organizationData),
                  created: null,
                  citations: [],
                  feedback: "",
                  id: null,
                  type: "answer",
                  url: "",
                }}
                playingMessage={playingMessage}
                setPlayingMessage={setPlayingMessage}
                setApiError={setApiError}
                isStandaloneWidget={isStandaloneWidget}
                isDocumentWidget={isDocumentWidget}
                isScreenLg={isScreenLg}
                organizationData={organizationData}
                chatData={chatData!}
                navigate={navigate}
              />
              {messagesData.map(
                (messageData: IQuestion | IAnswer, index: number) => {
                  if (messageData.type === "question") {
                    return (
                      <QuestionComponent key={index} question={messageData} />
                    );
                  } else if (messageData.type === "answer") {
                    return (
                      <AnswerComponent
                        key={index}
                        answer={messageData}
                        playingMessage={playingMessage}
                        setPlayingMessage={setPlayingMessage}
                        setApiError={setApiError}
                        isStandaloneWidget={isStandaloneWidget}
                        isDocumentWidget={isDocumentWidget}
                        isScreenLg={isScreenLg}
                        organizationData={organizationData}
                        chatData={chatData!}
                        navigate={navigate}
                      />
                    );
                  }
                  return null;
                },
              )}
            </div>

            <Form className={"mt-3"} onSubmit={handleMessageSend}>
              <div className={"d-flex"}>
                <InputGroup
                  className={"rounded-5 border border-1 border-light-subtle"}
                >
                  <Form.Control
                    type={"text"}
                    as={"textarea"}
                    // Allow minimum 1 and maximum 10 rows.
                    rows={Math.min(
                      Math.max(message.split(/\r\n|\r|\n/).length, 1),
                      10,
                    )}
                    onKeyDown={(e) => {
                      // Submit the form on Enter key press
                      // (unless Shift key is also pressed).
                      if (e.key.toLowerCase() === "enter" && !e.shiftKey) {
                        e.currentTarget.form?.requestSubmit();
                      }
                    }}
                    placeholder={
                      messagesData.length > 0
                        ? "Stel hier je vervolgvraag..."
                        : "Stel hier je vraag..."
                    }
                    aria-label={
                      messagesData.length > 0
                        ? "Stel hier je vervolgvraag..."
                        : "Stel hier je vraag..."
                    }
                    required={true}
                    value={message}
                    autoFocus={true}
                    disabled={isLoading || isAnswering}
                    onChange={(e) => setMessage(e.target.value)}
                    className={"rounded-5 bg-light border-5 border-light z-2"}
                    style={{ paddingRight: "7rem", maxHeight: "18rem" }}
                    ref={inputFieldRef}
                    // Limit the input to 1000 characters
                    // (which should be well withing the LLM's limit of 8192 tokens
                    // and sufficient for asking a complete question).
                    maxLength={maxMessageLength}
                  />
                  <div className={"d-flex"}>
                    <Button
                      title={"Verstuur"}
                      variant="success"
                      type="submit"
                      disabled={
                        isLoading ||
                        isAnswering ||
                        message.length === 0 ||
                        message.length > maxMessageLength
                      }
                      className={
                        "d-flex align-items-center position-absolute end-0 bottom-0 rounded-pill border-5 border-light px-3 z-3"
                      }
                    >
                      {isScreenLg ? <Send className={"me-2"} /> : <></>}
                      Verstuur
                    </Button>
                  </div>
                </InputGroup>
                {!isStandaloneWidget && !isDocumentWidget ? (
                  <RecordComponent
                    setIsAnswering={setIsAnswering}
                    setMessage={setMessage}
                    setMessagesData={setMessagesData}
                    chatData={chatData!}
                    setChatData={setChatData}
                    setApiError={setApiError}
                    dispatch={dispatch}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className={"text-start small ms-3"}>
                <Form.Text className="text-muted">
                  {message.length}/{maxMessageLength}
                </Form.Text>
              </div>
            </Form>

            {!isStandaloneWidget &&
            !isDocumentWidget &&
            messagesData.length > 0 ? (
              <NewChatComponent
                isLoading={isLoading}
                isAnswering={isAnswering}
                setChatData={setChatData}
                setMessagesData={setMessagesData}
                setApiError={setApiError}
                setMessage={setMessage}
                setIsLoading={setIsLoading}
                setIsAnswering={setIsAnswering}
                dispatch={dispatch}
                navigate={navigate}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}
