import * as Sentry from "@sentry/react";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";

import "./App.css";
import Dashboard from "./components/screens/dashboard/Dashboard";
import Login from "./components/screens/auth/Login";
import Signup from "./components/screens/auth/Signup";
import { Auth, isAuthenticatedIndication } from "./components/common/api/Auth";
import PasswordResetRequest from "./components/screens/auth/PasswordResetRequest";
import PasswordResetSetPassword from "./components/screens/auth/PasswordResetSetPassword";
import { useAppDispatch } from "./hooks";
import { updateIsScreenLg } from "./features/isScreenLgSlice";
import StandaloneWidget from "./components/screens/standalone-widget/StandaloneWidget";
import { setOrganizationApiKeyInRedux } from "./components/common/api/Utils";
import DocumentChatWidget from "./components/common/document-viewer/widget/DocumentChatWidget";

function App(): React.JSX.Element {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );

  React.useEffect(() => {
    /**
     * This loads once, when the application is loaded.
     * 1. On screen size change, update the screen size state.
     * 2. Check if there's an organization API key in the query parameter.
     *    a. If there's an API key, this means we're in the standalone widget.
     *    b. If there's no API key, this means we're in the full application.
     */
    // On screen size change, update the screen size state.
    window.addEventListener("resize", () => {
      dispatch(updateIsScreenLg());
    });
    // Check if there's an organization API key in the query parameter.
    const organizationApiKey: string | null = new URLSearchParams(
      window.location.search,
    ).get("x-org-key");
    if (organizationApiKey) {
      // If there's an API key, this means we're in the standalone widget.
      // Store/update the API key in redux.
      setOrganizationApiKeyInRedux(organizationApiKey).then(() => {
        // The user is technically not authenticated, so we set the state to false.
        setIsAuthenticated(false);
      });
    } else {
      // If there's no API key, this means we're in the full application.
      // Sync the session (if any) and set the authentication state.
      Auth.syncSession().then(async () => {
        await isAuthenticatedIndication().then((_isAuthenticated) => {
          setIsAuthenticated(_isAuthenticated);
        });
      });
    }
  }, []);

  React.useEffect(() => {
    /**
     * On every page/screen change, check if the user is (still) authenticated.
     */
    if (isAuthenticated !== null) {
      isAuthenticatedIndication().then((_isAuthenticated) => {
        setIsAuthenticated(_isAuthenticated);
      });
    }
  }, [location.pathname]);

  return (
    <div className={"App d-flex flex-column mh-100vh bg-white text-dark"}>
      <div className={"d-flex flex-fill"}>
        {isAuthenticated === null ? (
          <div className={"flex-fill"}>
            <div
              className={
                "d-flex h-100 justify-content-center align-items-center"
              }
            >
              <Spinner animation="border" variant="secondary" />
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="widget/standalone/*" element={<StandaloneWidget />} />
            {isAuthenticated ? (
              <>
                <Route path="*" element={<Navigate to="dashboard" />} />
                <Route path="dashboard/*" element={<Dashboard />} />
                <Route
                  path="dashboard/widget/document-chat/*"
                  element={<DocumentChatWidget />}
                />
              </>
            ) : (
              <>
                <Route path="*" element={<Navigate to="login" />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route
                  path="password-reset/request"
                  element={<PasswordResetRequest />}
                />
                <Route
                  path="password-reset/reset"
                  element={<PasswordResetSetPassword />}
                />
              </>
            )}
          </Routes>
        )}
      </div>
    </div>
  );
}

export default Sentry.withProfiler(App);
