import React from "react";

import config from "../../../../config";

export default function DocumentChatWidgetWrapper(props: {
  document: IDocument;
}): React.JSX.Element {
  /**
   * The wrapper for a document-specific chat widget,
   * using the shared `widget_wrapper.html` template.
   */
  const [widgetWrapperHtml, setWidgetWrapperHtml] = React.useState("");

  React.useEffect(() => {
    /**
     * Load the widget wrapper's HTML template.
     */
    fetch(
      `${config.backendBaseUrl}/widget/widget_wrapper.html?document=${props.document.id}`,
    ).then((response: Response) => {
      response.text().then((_widgetWrapperHtml: string) => {
        setWidgetWrapperHtml(_widgetWrapperHtml);
      });
    });
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: widgetWrapperHtml }} />;
}
