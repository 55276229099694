import React, { FormEvent, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import BrandedCardFooter from "../../common/BrandedCardFooter";
import { Auth, isAuthenticatedIndication } from "../../common/api/Auth";
import BrandedCardHeader from "../../common/BrandedCardHeader";
import { useAppSelector } from "../../../hooks";

export default function PasswordResetSetPassword(): React.JSX.Element {
  const navigate = useNavigate();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [formDataKey, setFormDataKey] = React.useState<string>("");
  const [formDataPassword, setFormDataPassword] = React.useState<string>("");
  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);

  useEffect(() => {
    isAuthenticatedIndication().then((isAuthenticated) => {
      if (isAuthenticated) {
        // Redirect to the root/chats page, if already authenticated.
        navigate("/dashboard/chats");
      }
    });
    // Get the password reset key from the URL.
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("key")) {
      // Redirect to the login page, if password reset key is missing.
      navigate("/login");
    } else {
      setFormDataKey(urlParams.get("key") || "");
      // Remove query params from URL.
      // window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [navigate]);

  const submitForm = async (e: FormEvent): Promise<void> => {
    /**
     * Set a new password.
     */
    e.preventDefault();
    // Clear previous errors, if any.
    setApiError(undefined);
    // Prepare form data.
    const formData = {
      key: formDataKey,
      password: formDataPassword,
    };
    // Set the new password.
    await Auth.auth(formData, "password/reset").then(
      () => {
        setShowSuccessPage(true);
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  return (
    <div className={"w-100 d-flex justify-content-center align-items-center"}>
      <Card
        className={"rounded-3 border-1 border-light-subtle bg-light m-3"}
        style={{ width: "100%", maxWidth: "25rem" }}
      >
        <Card.Header className={"rounded-top-3 border-0 bg-light"}>
          <BrandedCardHeader
            title={"Nieuw wachtwoord instellen"}
            isScreenLg={isScreenLg}
          />
        </Card.Header>

        <Form onSubmit={submitForm}>
          <Card.Body className={"bg-light px-4 pb-4"}>
            {showSuccessPage ? (
              <p className={"m-0"}>Het nieuwe wachtwoord is ingesteld.</p>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label column={false} className={"small text-muted mb-1"}>
                  Nieuw wachtwoord
                </Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Nieuw wachtwoord"
                  value={formDataPassword}
                  onChange={(e) => setFormDataPassword(e.target.value)}
                  required={true}
                  className={
                    "rounded-pill py-2 px-3 border-2 border-light-subtle"
                  }
                />
                {apiError?.errorData?.errors?.map(
                  (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    errorMessage: { [key: string]: any },
                    _index: number,
                  ) => {
                    return (
                      <Form.Text
                        key={`error-${errorMessage.param}-${_index}`}
                        className="text-danger"
                      >
                        {errorMessage.message}
                        <br />
                      </Form.Text>
                    );
                  },
                )}
              </Form.Group>
            )}
            <div className={"d-flex justify-content-end mt-4"}>
              {showSuccessPage ? (
                <Button
                  title={"Ok"}
                  variant="dark"
                  type="button"
                  onClick={() => navigate("/login")}
                  className={"rounded-3"}
                >
                  Ok
                </Button>
              ) : (
                <Button
                  title={"Wachtwoord opslaan"}
                  variant="dark"
                  type="submit"
                  className={"rounded-3"}
                >
                  Wachtwoord opslaan
                </Button>
              )}
            </div>
          </Card.Body>
          <Card.Footer
            className={
              "rounded-bottom-3 d-flex align-items-center justify-content-between text-muted border-0 py-3"
            }
          >
            <BrandedCardFooter />
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}
