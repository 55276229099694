import React, { useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { getOrganizationFromRedux } from "../../../common/api/Utils";
import BrandedCardHeader from "../../../common/BrandedCardHeader";
import { useAppSelector } from "../../../../hooks";
import BrandedCardFooter from "../../../common/BrandedCardFooter";
import { hasAdminRights } from "../../../common/api/Auth";

export default function WidgetInstructions(): React.JSX.Element {
  const navigate = useNavigate();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);
  const apiKeyPlaceholder: string = "YOUR_API_KEY";

  const [apiKey, setApiKey] = React.useState<string>(apiKeyPlaceholder);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    /**
     * Admin screen, so redirect non-admin users to the dashboard root.
     * Retrieve the organization API key from storage, if any.
     */
    setIsLoading(true);
    hasAdminRights().then(async (_hasAdminRights) => {
      if (!_hasAdminRights) {
        navigate("/dashboard");
      } else {
        await getOrganizationFromRedux().then(
          (organization: IOrganization | null) => {
            setApiKey(organization?.apiKey || apiKeyPlaceholder);
            setIsLoading(false);
          },
        );
      }
    });
  }, []);

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <h6 className={"m-0"}>Widget</h6>
      </div>

      <div className={"d-flex justify-content-center align-items-center h-100"}>
        {isLoading ? (
          <div className={"d-flex justify-content-center align-items-center"}>
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <Card
            className={"rounded-3 border-1 border-light-subtle bg-light mt-0"}
            style={{ width: "100%", maxWidth: "25rem" }}
          >
            <Card.Header className={"rounded-top-3 border-0 bg-light"}>
              <BrandedCardHeader title={"Widget"} isScreenLg={isScreenLg} />
            </Card.Header>

            <Card.Body className={"bg-light px-4 pb-4"}>
              <div>
                <div className={"small text-muted my-1"}>Installatie</div>
                <p>
                  Manual is ook beschikbaar in de vorm van een widget. Deze kan
                  worden toegevoegd aan een eigen website, om bezoekers daar ook
                  toegang te geven tot de documenten van deze organisatie.
                  <br />
                  Om de Manual widget toe te voegen, kopieer en plak het
                  volgende script in het HTML <code>{"<head>"}</code> gedeelte
                  van de webpagina.
                </p>
                <code>
                  <pre
                    className={
                      "mb-0 text-wrap bg-light-subtle border-1 border rounded-3 p-3"
                    }
                  >
                    {`<script src="https://api.manual.app/widget/script.js?key=${apiKey}"></script>`}
                  </pre>
                </code>
                {apiKey === apiKeyPlaceholder ? (
                  <p className={"mb-0 mt-3"}>
                    Neem a.u.b. contact op met{" "}
                    <a
                      href={"mailto:support@manual.app"}
                      target={"_blank"}
                      rel={"noreferrer"}
                      className={"text-dark"}
                    >
                      support@manual.app
                    </a>{" "}
                    voor het verkrijgen van een API key.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
            <Card.Footer className="rounded-bottom-3 d-flex align-items-center justify-content-between text-muted border-0 py-3">
              <BrandedCardFooter />
            </Card.Footer>
          </Card>
        )}
      </div>
    </div>
  );
}
