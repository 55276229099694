import React from "react";

import { Api } from "../../../../common/api/Api";
import {
  modifyChatTopic,
  addChat,
} from "../../../../../features/recentChatsSlice";
import { AppDispatch } from "../../../../../store";

const defaultError: string = "Sorry, op deze vraag heb ik geen antwoord.";

export const scrollToBottomOfChatWindow = () => {
  /**
   * Scroll to the bottom of the chat window.
   * If the chat has answers, scroll to the top of the latest answer.
   */
  const chatWindow: HTMLElement | null = document.getElementById("chatWindow");
  if (chatWindow) {
    const latestAnswer: HTMLElement | null = chatWindow.querySelector(
      ".answer-component:last-child",
    );
    if (latestAnswer) {
      latestAnswer.scrollIntoView();
    } else {
      chatWindow.scrollTo(0, chatWindow.scrollHeight);
    }
  }
};

export const blobToSpeech = async (blob: Blob): Promise<ISpeech> => {
  /**
   * Convert a blob to a base64 encoded string.
   */
  const encoding = blob.type;
  let binary = "";
  const bytes = new Uint8Array(await blob.arrayBuffer());
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const data = window.btoa(binary);
  return {
    encoding: encoding,
    data: data,
  };
};

export const isApiError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: IApiError | { [key: string]: any },
): obj is IApiError => {
  /**
   * Check if the object is an API error.
   */
  return (obj as IApiError).errorMessage !== undefined;
};

export const getTextFromSpeech = async (
  speech: ISpeech,
): Promise<string | null> => {
  /**
   * Get text from speech.
   */
  try {
    const response = await Api.post("transcriptions/", {
      encoding: speech.encoding,
      data: speech.data,
    });
    if (isApiError(response)) {
      return null;
    }
    return response.text;
  } catch (e) {
    console.log("An error occurred  :( ", e);
    return null;
  }
};

export const organizationHasDocuments = async (): Promise<boolean> => {
  /**
   * Check if the organization has any documents.
   */
  return await Api.get("documents/any/").then(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: { [key: string]: any }) => {
      return response?.exists === true;
    },
    () => {
      return false;
    },
  );
};

const postMessage = async (
  message: string,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >,
  chatData: IChat,
  setChatData: React.Dispatch<React.SetStateAction<IChat | null>>,
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
  dispatch: AppDispatch,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ [key: string]: any } | IApiError> => {
  /**
   * Post a message to Manual server.
   */
  // Clear message input field.
  setMessage("");
  // Add the question message.
  const _questionMessageData: IQuestion = {
    type: "question",
    url: "",
    message: message,
    created: new Date(),
  };
  setMessagesData((_messagesData) => [..._messagesData, _questionMessageData]);
  // Add the answer message (placeholder).
  const _answerMessagePlaceholderData: IAnswer = {
    type: "answer",
    id: null,
    url: "",
    message: "",
    created: null,
    citations: [],
    feedback: "",
  };
  setMessagesData((_messagesData) => [
    ..._messagesData,
    _answerMessagePlaceholderData,
  ]);
  setTimeout(() => {
    scrollToBottomOfChatWindow();
  }, 1);
  // Prepare the form data.
  const data: FormData = new FormData();
  data.append("message", message);
  data.append("chat", chatData.url);
  // Make the request.
  return Api.post("questions/", data).then(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: { [key: string]: any }) => {
      // If we got a new topic, we can update the chat topic.
      if (chatData.topic !== response.chat_topic) {
        setChatData((_chatData) => {
          return _chatData
            ? { ..._chatData, topic: response.chat_topic }
            : _chatData;
        });
        // Modify in the redux store so other components can get the new topic.
        // But also set our own topic
        dispatch(
          modifyChatTopic({
            id: chatData.id,
            topic: response.chat_topic,
          }),
        );
      }
      return response;
    },
    (error: IApiError) => {
      setApiError(error);
      return error;
    },
  );
};

export const handleMessageSendInner = async (
  message: string,
  setIsAnswering: React.Dispatch<React.SetStateAction<boolean>>,
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
  chatData: IChat,
  setChatData: React.Dispatch<React.SetStateAction<IChat | null>>,
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
  dispatch: AppDispatch,
) => {
  setIsAnswering(true);
  await postMessage(
    message,
    setMessage,
    setMessagesData,
    chatData,
    setChatData,
    setApiError,
    dispatch,
  )
    .then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (responseQuestion: { [key: string]: any } | any) => {
        // Remove the placeholder answer message.
        setMessagesData((_messagesData) => _messagesData.slice(0, -1));
        // Add the answer message.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _answerData: { [key: string]: any } = responseQuestion.answer;
        if (_answerData) {
          // Get the citations for the answer, if any.
          const _citationsData: Array<ICitation> = [];
          _answerData.citations.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            async (_citationData: { [key: string]: any }) => {
              _citationsData.push({
                document: {
                  id: _citationData.document_chunk.document.id,
                  url: _citationData.document_chunk.document.file_download_url,
                  extension: _citationData.document_chunk.document.extension,
                  name: `${_citationData.document_chunk.document.title}.${_citationData.document_chunk.document.extension}`,
                },
                pageStart: _citationData.document_chunk.page_number_start,
                pageEnd: _citationData.document_chunk.page_number_end,
              });
            },
          );
          // Add the answer message.
          const _answerMessageData: IAnswer = {
            type: "answer",
            id: _answerData.id,
            url: _answerData.url,
            message: _answerData.message,
            created: new Date(_answerData.created),
            citations: _citationsData,
            feedback: _answerData.feedback,
          };
          setMessagesData((_messagesData) => [
            ..._messagesData,
            _answerMessageData,
          ]);
        } else {
          const _answerMessageErrorData: IAnswer = {
            type: "answer",
            id: null,
            url: "",
            message: defaultError,
            created: null,
            citations: [],
            feedback: "",
          };
          setMessagesData((_messagesData) => [
            ..._messagesData,
            _answerMessageErrorData,
          ]);
        }
      },
      () => {
        // Show error message.
        // Remove the placeholder answer message.
        setMessagesData((_messagesData) => _messagesData.slice(0, -1));
        // Add the error answer message.
        const _answerMessageErrorData: IAnswer = {
          type: "answer",
          id: null,
          url: "",
          message: defaultError,
          created: null,
          citations: [],
          feedback: "",
        };
        setMessagesData((_messagesData) => [
          ..._messagesData,
          _answerMessageErrorData,
        ]);
      },
    )
    .finally(() => {
      setIsAnswering(false);
      setTimeout(() => {
        scrollToBottomOfChatWindow();
      }, 1);
    });
};

export const getAssistantGreeting = (
  organizationData: IOrganization | null,
): string => {
  if (organizationData?.assistantGreeting) {
    return organizationData.assistantGreeting;
  } else if (organizationData?.assistantName) {
    return `Ik ben ${organizationData.assistantName}, jouw persoonlijke AI assistent. Waar kan ik mee helpen?`;
  } else {
    return "Waar kan ik mee helpen?";
  }
};

export const initializeChatMessages = async (
  chatId: string,
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >,
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
) => {
  /**
   * Initialize the chat's messages (questions and answers), if any.
   */
  await Api.get(`chats/${chatId}/`).then(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: { [key: string]: any }) => {
      // Get the question(s) for the chat, if any.
      response.questions.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (_questionData: { [key: string]: any }) => {
          const _questionMessageData: IQuestion = {
            type: "question",
            url: _questionData.url,
            message: _questionData.message,
            created: new Date(_questionData.created),
          };
          setMessagesData((_messagesData) => [
            ..._messagesData,
            _questionMessageData,
          ]);
          // Get the answer for the question, if any.
          if (_questionData.answer) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _answerData: { [key: string]: any } = _questionData.answer;
            // Get the citations for the answer, if any.
            const _citationsData: Array<ICitation> = [];
            _answerData.citations.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              async (_citationData: { [key: string]: any }) => {
                _citationsData.push({
                  document: {
                    id: _citationData.document_chunk.document.id,
                    url: _citationData.document_chunk.document
                      .file_download_url,
                    extension: _citationData.document_chunk.document.extension,
                    name: `${_citationData.document_chunk.document.title}.${_citationData.document_chunk.document.extension}`,
                  },
                  pageStart: _citationData.document_chunk.page_number_start,
                  pageEnd: _citationData.document_chunk.page_number_end,
                });
              },
            );
            // Add the answer message.
            const _answerMessageData: IAnswer = {
              type: "answer",
              id: _answerData.id,
              url: _answerData.url,
              message: _answerData.message,
              created: new Date(_answerData.created),
              citations: _citationsData,
              feedback: _answerData.feedback,
            };
            setMessagesData((_messagesData) => [
              ..._messagesData,
              _answerMessageData,
            ]);
          } else {
            const _answerMessageErrorData: IAnswer = {
              type: "answer",
              id: null,
              url: "",
              message: defaultError,
              created: null,
              citations: [],
              feedback: "",
            };
            setMessagesData((_messagesData) => [
              ..._messagesData,
              _answerMessageErrorData,
            ]);
          }
        },
      );
    },
    (error: IApiError) => {
      setApiError(error);
    },
  );
};

export const handleCopyAnswer = (answer: IAnswer): void => {
  if (!navigator.clipboard && !!document.execCommand) {
    // Clipboard API may not be available. In that case we create a textarea and copy the answer to clipboard.
    // Note that execCommand is deprecated and may be removed in the future.
    // In that case we simply do nothing...
    const textArea = document.createElement("textarea");
    textArea.value = answer.message;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    document.body.removeChild(textArea);
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(answer.message).then(() => {});
  }
};

export const createNewChat = async (
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
  dispatch: AppDispatch,
): Promise<ISerializableChat | null> => {
  /**
   * Create a new chat.
   */
  return await Api.post("chats/", {}).then(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: { [key: string]: any }) => {
      const chat: ISerializableChat = {
        id: response.id,
        url: response.url,
        topic: "",
        created: response.created,
        modified: response.modified,
      };
      // Add the dispatch here, to add the new chat to recent chats.
      dispatch(addChat(chat));
      // Return the newly created chat.
      return chat;
    },
    (error: IApiError) => {
      setApiError(error);
      return null;
    },
  );
};

export const clearChatMessages = async (
  setChatData: React.Dispatch<React.SetStateAction<IChat | null>>,
  setMessagesData: React.Dispatch<
    React.SetStateAction<Array<IQuestion | IAnswer>>
  >,
  setApiError: React.Dispatch<React.SetStateAction<IApiError | null>>,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsAnswering: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  /**
   * Method to reset state in case user navigates to another/new chat.
   */
  setChatData(null);
  setMessagesData([]);
  setApiError(null);
  setMessage("");
  setIsAnswering(false);
  return;
};
