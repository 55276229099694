import { AxiosError, AxiosResponse } from "axios";

import { apiErrorGeneric, getAxiosInstance, handleAxiosError } from "./Utils";

export class Api {
  static async get(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: { [key: string]: any },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ [key: string]: any } | IApiError> {
    const isUserManagementRelated: boolean =
      path.includes("users/") || path.includes("invites/");
    const axiosInstance = await getAxiosInstance(
      false,
      isUserManagementRelated,
    ).then((_axiosInstance) => {
      return _axiosInstance;
    });
    return await axiosInstance.get(path, { ...config }).then(
      async (response: AxiosResponse) => {
        if ([200].includes(response.status)) {
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        await handleAxiosError(error);
      },
    );
  }

  static async getPaginated(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ [key: string]: any }[] | IApiError> {
    // Get all pages of a paginated API endpoint
    // This assumes there is a "next" key and a "resuklts" key in the response.
    // ONLY use this for django rest framework paginated responses.
    let err: null | IApiError = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const results: { [key: string]: any }[] = [];
    let nextPage: string | null = path;

    // eslint will complain when we define the function inside the loop
    // While defining the function inside the loop will work, it can cause
    // issues. Therefore, we define the functions outside the loop.
    // See this stack overflow answer as to why.
    // https://stackoverflow.com/a/59783915
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const processResponse = (response: { [key: string]: any }) => {
      if (response.next !== null) {
        if (window.location.protocol === "https:") {
          nextPage = response.next.replace("http://", "https://");
        }
      } else {
        nextPage = response.next;
      }
      if (response.results !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiResults: { [key: string]: any }[] = response.results;
        results.push(...apiResults);
      }
    };
    const processError = (error: IApiError) => {
      err = error;
      nextPage = null; // break out of the loop in case
    };
    while (nextPage !== null) {
      await this.get(nextPage).then(processResponse, processError);
    }

    if (err !== null) {
      return err;
    }
    return results;
  }

  static async post(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ [key: string]: any } | IApiError> {
    const isFileUpload: boolean = path.includes("documents/");
    const isUserManagementRelated: boolean = path.includes("invitations/");
    const axiosInstance = await getAxiosInstance(
      isFileUpload,
      isUserManagementRelated,
    ).then((_axiosInstance) => {
      return _axiosInstance;
    });
    return await axiosInstance.post(path, data).then(
      async (response: AxiosResponse) => {
        if ([201, 202].includes(response.status)) {
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        await handleAxiosError(error);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async patch(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ [key: string]: any } | IApiError> {
    const axiosInstance = await getAxiosInstance().then((_axiosInstance) => {
      return _axiosInstance;
    });
    return await axiosInstance.patch(path, data).then(
      async (response: AxiosResponse) => {
        if ([200, 201].includes(response.status)) {
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        await handleAxiosError(error);
      },
    );
  }

  static async delete(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<{ [key: string]: any } | IApiError> {
    const isFileUpload: boolean = path.includes("documents/");
    const isUserManagementRelated: boolean =
      path.includes("invitations/") || path.includes("invites/");
    const axiosInstance = await getAxiosInstance(
      isFileUpload,
      isUserManagementRelated,
    ).then((_axiosInstance) => {
      return _axiosInstance;
    });
    return await axiosInstance.delete(path).then(
      async (response: AxiosResponse) => {
        if ([200, 201, 202, 204].includes(response.status)) {
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        await handleAxiosError(error);
      },
    );
  }
}
