import React from "react";
import { Route, Routes } from "react-router-dom";

import ChatsDetail from "../../../screens/dashboard/chats/detail/ChatsDetail";
import { Api } from "../../api/Api";
import { baseApiUrl } from "../../api/Utils";

export default function DocumentChatWidget(): React.JSX.Element {
  /**
   * This is the document-specific chat widget (inner part),
   * used in the DocumentViewer.
   */
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [documentSpecificChat, setDocumentSpecificChat] =
    React.useState<IChat | null>(null);

  React.useEffect(() => {
    // The query parameter should contain a document ID
    // (set in `DocumentChatWidgetWrapper`).
    const documentId: string | null = new URLSearchParams(
      window.location.search,
    ).get("document-id");
    if (documentId) {
      // Try to get the document-specific chat.
      Api.get(`chats/?document=${documentId}`).then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (response: { [key: string]: any }) => {
          if (response.results.length) {
            // Existing document-specific chat.
            // We take the first one, there shouldn't be multiple
            // (each document can only have one document-specific chat per user).
            const chat: IChat = {
              id: response.results[0].id,
              url: response.results[0].url,
              topic: response.results[0].topic,
              created: new Date(response.results[0].created),
              modified: new Date(response.results[0].modified),
            };
            setDocumentSpecificChat(chat);
            setIsLoading(false);
          } else {
            // Create new document-specific chat, if none exists.
            await Api.post("chats/", {
              document: `${baseApiUrl}documents/${documentId}/`,
            }).then(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (response: { [key: string]: any }) => {
                const chat: IChat = {
                  id: response.id,
                  url: response.url,
                  topic: response.topic,
                  created: new Date(response.created),
                  modified: new Date(response.modified),
                };
                setDocumentSpecificChat(chat);
                setIsLoading(false);
              },
            );
          }
        },
      );
    }
  }, []);

  return (
    <div
      className={"flex-fill"}
      // To make flexbox and truncated text play nice together
      // (https://css-tricks.com/flexbox-truncated-text/).
      style={{ minWidth: 0 }}
    >
      <div className={"d-flex flex-column h-100"}>
        <div className={"d-flex h-100"}>
          <div
            className={"pt-1 pb-3 px-3 bg-white flex-grow-1"}
            // To make flexbox and truncated text play nice together
            // (https://css-tricks.com/flexbox-truncated-text/).
            style={{ minWidth: 0 }}
          >
            {isLoading ? (
              <></>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={
                    <ChatsDetail documentSpecificChat={documentSpecificChat} />
                  }
                />
              </Routes>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
