import React from "react";

export default function BrandedCardFooter(): React.JSX.Element {
  return (
    <div className={"w-100 small text-center"}>
      Voor hulp of vragen:&nbsp;
      <a
        href={"mailto:support@manual.app"}
        target={"_blank"}
        rel={"noreferrer"}
        className={"text-muted"}
      >
        support@manual.app
      </a>
      .
      <br />
      Manual is onderdeel van&nbsp;
      <a
        href={"https://clockworks.co/"}
        target={"_blank"}
        rel={"noreferrer"}
        className={"text-muted"}
      >
        Clockworks
      </a>
      .
    </div>
  );
}
