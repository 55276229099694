import React from "react";
import { Card } from "react-bootstrap";
import parse from "html-react-parser";

import { dateToString } from "../../../../../common/Utils";

export const QuestionComponent = ({
  question,
}: {
  question: IQuestion;
}): React.JSX.Element => {
  return (
    <div className={"d-flex justify-content-end mb-3"}>
      <Card className={"w-75 bg-light rounded-3 border-1 border-light-subtle"}>
        <Card.Body>
          <p className={"m-0"}>
            {parse(question.message.replace(/\n/g, "<br/>"))}
          </p>
        </Card.Body>
        {question.created ? (
          <Card.Footer
            className="d-flex align-items-center justify-content-between text-muted border-0 rounded-bottom-3"
            style={{ minHeight: "3rem" }}
          >
            <small>{dateToString(question.created)}</small>
          </Card.Footer>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
};
