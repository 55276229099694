import React, { FormEvent, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Auth, isAuthenticatedIndication } from "../../common/api/Auth";
import BrandedCardFooter from "../../common/BrandedCardFooter";
import BrandedCardHeader from "../../common/BrandedCardHeader";
import { useAppSelector } from "../../../hooks";

export default function Signup(): React.JSX.Element {
  const navigate = useNavigate();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [organization, setOrganization] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [formDataPassword, setFormDataPassword] = React.useState<string>("");
  const [apiError, setApiError] = React.useState<IApiError | undefined>();

  useEffect(() => {
    isAuthenticatedIndication().then((isAuthenticated) => {
      if (isAuthenticated) {
        // Redirect to the root/chats page, if already authenticated.
        navigate("/dashboard/chats");
      }
    });
    // Get the invitee details from the URL.
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("email") || !urlParams.has("organization")) {
      // Redirect to the login page, if invitee details are missing.
      navigate("/login");
    } else {
      setOrganization(urlParams.get("organization") || "");
      setEmail(urlParams.get("email") || "");
      // Remove query params from URL.
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [navigate]);

  const submitForm = async (e: FormEvent): Promise<void> => {
    /**
     * Signup a user via email/password.
     */
    e.preventDefault();
    // Clear previous errors, if any.
    setApiError(undefined);
    // Prepare form data.
    const formData = {
      email: email,
      password: formDataPassword,
    };
    // Signup the user.
    await Auth.auth(formData, "signup").then(
      () => {
        navigate("/dashboard/chats");
      },
      (error: IApiError) => {
        console.log("error", error);
        setApiError(error);
      },
    );
  };

  return (
    <div className={"w-100 d-flex justify-content-center align-items-center"}>
      <Card
        className={"rounded-3 border-1 border-light-subtle bg-light m-3"}
        style={{ width: "100%", maxWidth: "25rem" }}
      >
        <Card.Header className={"rounded-top-3 border-0 bg-light"}>
          <BrandedCardHeader
            title={"Account activeren"}
            isScreenLg={isScreenLg}
          />
        </Card.Header>

        <Form onSubmit={submitForm}>
          <Card.Body className={"bg-light px-4 pb-4"}>
            <div className="mb-3">
              <div className={"small text-muted my-1"}>Organisatie</div>
              <p>{organization}</p>
            </div>

            <div className="mb-3">
              <div className={"small text-muted my-1"}>Email</div>
              <p>{email}</p>
            </div>

            <Form.Group className="mb-2" controlId="formPassword">
              <Form.Label column={false} className={"small text-muted mb-1"}>
                Wachtwoord instellen
              </Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Wachtwoord instellen"
                value={formDataPassword}
                onChange={(e) => setFormDataPassword(e.target.value)}
                required={true}
                className={
                  "rounded-pill py-2 px-3 border-2 border-light-subtle"
                }
              />
              {apiError?.errorData?.errors?.map(
                (
                  errorMessage: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    [key: string]: any;
                  },
                  _index: number,
                ) => {
                  return (
                    <Form.Text
                      key={`error-${errorMessage.param}-${_index}`}
                      className="text-danger"
                    >
                      {errorMessage.message}
                      <br />
                    </Form.Text>
                  );
                },
              )}
            </Form.Group>

            <div className={"d-flex justify-content-end mt-3"}>
              <Button
                title={"Account activeren"}
                variant="dark"
                type="submit"
                className={"rounded-3"}
              >
                Account activeren
              </Button>
            </div>
          </Card.Body>

          <Card.Footer
            className={
              "rounded-bottom-3 d-flex align-items-center justify-content-between text-muted border-0 py-3"
            }
          >
            <BrandedCardFooter />
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}
