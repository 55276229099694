import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";
import { Button, Spinner } from "react-bootstrap";

import { truncateString } from "../../../common/Utils";
import { getDocumentBlobUrl } from "../../../common/api/Utils";
import { DocumentViewer } from "../../../common/document-viewer/DocumentViewer";
import { useAppSelector } from "../../../../hooks";
import { DocumentDownload } from "../../../common/document-viewer/DocumentDownloadButton";

export default function ChatsDetailCitation(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const citationData: ICitation = location?.state?.citation;
  const citationIndex: number = location?.state?.citationIndex;
  const chatTopic: string = location?.state?.chatTopic;
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsLoading(true);
    if (!citationData?.document?.url) {
      // If no citation/document data, redirect to chats overview
      // (this is probably caused by a user copy-pasting the URL).
      return navigate("/dashboard/chats");
    } else {
      getDocumentBlobUrl(citationData.document).then(
        (documentBlobUrl: string) => {
          setDocumentBlobUrl(documentBlobUrl);
          setIsLoading(false);
        },
      );
    }
  }, [navigate, citationData?.document]);

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div
        className={"d-flex align-items-center mb-3 py-1 text-muted"}
        // To make space for the document download button.
        style={isScreenLg ? { marginRight: "15rem" } : {}}
      >
        <Button
          title={"Vorige"}
          name={"Vorige"}
          type="button"
          onClick={() => navigate(-1)}
          className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
        >
          <ArrowLeft />
        </Button>
        <h6
          onClick={() => navigate("/dashboard/chats")}
          className={"m-0 ms-1 text-decoration-underline"}
          style={{ cursor: "pointer" }}
        >
          Chats
        </h6>
        <h6 className={"m-0 mx-2"}>/</h6>
        <h6
          onClick={() => navigate(-1)}
          className={"m-0 text-decoration-underline text-truncate"}
          style={{ cursor: "pointer" }}
        >
          Chat
          {isScreenLg && chatTopic ? (
            <span>
              : {` `}
              <i>{truncateString(chatTopic)}</i>
            </span>
          ) : (
            ""
          )}
        </h6>
        <h6 className={"m-0 mx-2"}>/</h6>
        <h6 className={"m-0 text-truncate"}>
          {isScreenLg ? (
            <>
              {citationIndex ? `Citatie ${citationIndex}:` : ""}{" "}
              {citationData?.document.name}
              {citationData?.pageStart && citationData?.pageEnd ? (
                <>
                  <> </>
                  {citationData.pageStart === citationData.pageEnd ? (
                    <>(pagina {citationData.pageStart})</>
                  ) : (
                    <>
                      (pagina&apos;s {citationData.pageStart}-
                      {citationData.pageEnd})
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            "Citatie"
          )}
        </h6>
        <DocumentDownload
          document={citationData?.document}
          documentBlobUrl={documentBlobUrl}
          isLoading={isLoading}
        />
      </div>

      {isLoading ? (
        <div
          className={"h-100 d-flex justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <DocumentViewer
          document={citationData.document}
          documentBlobUrl={documentBlobUrl}
          documentPageIndex={citationData.pageStart ?? 1}
        />
      )}
    </div>
  );
}
