import React, { FormEvent, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import BrandedCardFooter from "../../common/BrandedCardFooter";
import { Auth, isAuthenticatedIndication } from "../../common/api/Auth";
import BrandedCardHeader from "../../common/BrandedCardHeader";
import { useAppSelector } from "../../../hooks";

export default function PasswordResetRequest(): React.JSX.Element {
  const navigate = useNavigate();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [formDataEmail, setFormDataEmail] = React.useState<string>("");
  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);

  useEffect(() => {
    isAuthenticatedIndication().then((isAuthenticated) => {
      if (isAuthenticated) {
        // Redirect to the dashboard/chats page, if already authenticated.
        navigate("/dashboard/chats");
      }
    });
  }, [navigate]);

  const submitForm = async (e: FormEvent): Promise<void> => {
    /**
     * Request a password reset, by email.
     */
    e.preventDefault();
    // Clear previous errors, if any.
    setApiError(undefined);
    // Prepare form data.
    const formData = {
      email: formDataEmail,
    };
    // Login the user.
    await Auth.auth(formData, "password/request").then(
      () => {
        setShowSuccessPage(true);
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  return (
    <div className={"w-100 d-flex justify-content-center align-items-center"}>
      <Card
        className={"rounded-3 border-1 border-light-subtle bg-light m-3"}
        style={{ width: "100%", maxWidth: "25rem" }}
      >
        <Card.Header className={"rounded-top-3 border-0 bg-light"}>
          <BrandedCardHeader
            title={"Wachtwoord opnieuw instellen"}
            isScreenLg={isScreenLg}
          />
        </Card.Header>

        <Form onSubmit={submitForm}>
          <Card.Body className={"bg-light px-4 pb-4"}>
            {showSuccessPage ? (
              <p className={"m-0"}>
                Een mail is verstuurd naar <i>{formDataEmail}</i>, met een link
                om een nieuw wachtwoord in te stellen.
              </p>
            ) : (
              <div>
                <Form.Group className="mb-3">
                  <Form.Label
                    column={false}
                    className={"small text-muted mb-1"}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={formDataEmail}
                    onChange={(e) => setFormDataEmail(e.target.value)}
                    required={true}
                    className={
                      "rounded-pill py-2 px-3 border-2 border-light-subtle"
                    }
                  />
                  {apiError?.errorData?.errors?.map(
                    (
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      errorMessage: { [key: string]: any },
                      _index: number,
                    ) => {
                      return (
                        <Form.Text
                          key={`error-${errorMessage.param}-${_index}`}
                          className="text-danger"
                        >
                          {errorMessage.message}
                          <br />
                        </Form.Text>
                      );
                    },
                  )}
                </Form.Group>
                <div className={"d-flex justify-content-end mt-4"}>
                  <Button
                    title={"Wachtwoord opnieuw instellen"}
                    variant="dark"
                    type="submit"
                    className={"rounded-3"}
                  >
                    Wachtwoord opnieuw instellen
                  </Button>
                </div>
              </div>
            )}
          </Card.Body>

          <Card.Footer
            className={
              "rounded-bottom-3 d-flex align-items-center justify-content-between text-muted border-0 py-3"
            }
          >
            <BrandedCardFooter />
          </Card.Footer>
        </Form>
      </Card>
    </div>
  );
}
