import React from "react";
import { Nav, Spinner, Button, Navbar, Container } from "react-bootstrap";
import { Link, Route, Routes, Navigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  Chat,
  FileEarmark,
  Person,
  People,
  List,
  Window,
} from "react-bootstrap-icons";

import logo from "../../../assets/images/logo192.png";
import ChatsList from "./chats/ChatsList";
import MostRecentChats from "./chats/MostRecentChats";
import DocumentsList from "./documents/DocumentsList";
import DocumentsAdd from "./documents/DocumentsAdd";
import DocumentsDetail from "./documents/DocumentsDetail";
import ChatsDetailCitation from "./chats/ChatsDetailCitation";
import ChatsDetail from "./chats/detail/ChatsDetail";
import Account from "./account/Account";
import UsersList from "./users/UsersList";
import { getOrganizationFromRedux } from "../../common/api/Utils";
import { hasAdminRights } from "../../common/api/Auth";
import { useAppSelector } from "../../../hooks";
import WidgetInstructions from "./widget-instructions/WidgetInstructions";

export default function Dashboard(): React.JSX.Element {
  const pathName: string = window.location.pathname;

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [organizationData, setOrganizationData] = React.useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    IOrganization | any
  >({});
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isNavExpanded, setIsNavExpanded] = React.useState<boolean>(isScreenLg);

  React.useEffect(() => {
    /**
     * This loads once, when the dashboard is loaded.
     */
    setIsLoading(true);
    getOrganizationFromRedux().then(
      async (organization: IOrganization | null) => {
        setOrganizationData(organization);
        await hasAdminRights().then((_hasAdminRights) => {
          setIsAdmin(_hasAdminRights);
          setIsLoading(false);
        });
      },
    );
  }, []);

  const NavbarMobile = () => {
    /**
     * Navbar component for mobile (i.e. screens smaller than LG).
     */
    if (!isScreenLg) {
      return (
        <Navbar
          expand={false}
          collapseOnSelect={true}
          className="bg-light border-bottom border-1 border-light-subtle px-1"
        >
          <Container fluid={true}>
            <Navbar.Brand>
              <Link to={"/dashboard/chats"}>
                <img
                  alt="Logo"
                  title={"Logo"}
                  src={
                    organizationData && organizationData.logoUrl
                      ? organizationData.logoUrl
                      : logo
                  }
                  height="32"
                  className={"overflow-hidden"}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              className={"p-2 rounded-pill text-dark border-1 border-light"}
              style={{ height: "38px", width: "38px" }}
            >
              <span className={"d-flex align-items-center py-0"}>
                <List />
              </span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <LinkContainer to="/dashboard/chats">
                  <Nav.Link
                    eventKey={"chats"}
                    title={"Chats"}
                    className={"px-2 rounded-3"}
                    active={pathName.includes("chats")}
                  >
                    <div
                      className={
                        "d-flex align-items-center justify-content-end"
                      }
                    >
                      <Chat className={"my-1"} style={{ minWidth: "1rem" }} />
                      <span className={"ms-2 my-0"}>Chats</span>
                    </div>
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/dashboard/documents">
                  <Nav.Link
                    eventKey={"documents"}
                    title={"Documenten"}
                    className={"px-2 rounded-3"}
                    active={pathName.includes("documents")}
                  >
                    <div
                      className={
                        "d-flex align-items-center justify-content-end"
                      }
                    >
                      <FileEarmark
                        className={"my-1"}
                        style={{ minWidth: "1rem" }}
                      />
                      <span className={"ms-2 my-0"}>Documenten</span>
                    </div>
                  </Nav.Link>
                </LinkContainer>
                {isAdmin ? (
                  <>
                    <LinkContainer to="/dashboard/users">
                      <Nav.Link
                        eventKey={"users"}
                        title={"Gebruikers"}
                        className={"px-2 rounded-3"}
                        active={pathName.includes("users")}
                      >
                        <div
                          className={
                            "d-flex align-items-center justify-content-end"
                          }
                        >
                          <People
                            className={"my-1"}
                            style={{ minWidth: "1rem" }}
                          />
                          <span className={"ms-2 my-0"}>Gebruikers</span>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/dashboard/widget-instructions">
                      <Nav.Link
                        eventKey={"widget-instructions"}
                        title={"Widget"}
                        className={"px-2 rounded-3"}
                        active={pathName.includes("widget-instructions")}
                      >
                        <div
                          className={
                            "d-flex align-items-center justify-content-end"
                          }
                        >
                          <Window
                            className={"my-1"}
                            style={{ minWidth: "1rem" }}
                          />
                          <span className={"ms-2 my-0"}>Widget</span>
                        </div>
                      </Nav.Link>
                    </LinkContainer>
                  </>
                ) : (
                  <></>
                )}
                <LinkContainer to="/dashboard/account">
                  <Nav.Link
                    eventKey={"account"}
                    title={"Account"}
                    className={"px-2 rounded-3"}
                    active={pathName.includes("account")}
                  >
                    <div
                      className={
                        "d-flex align-items-center justify-content-end"
                      }
                    >
                      <Person className={"my-1"} style={{ minWidth: "1rem" }} />
                      <span className={"ms-2 my-0"}>Account</span>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div
      className={"flex-fill"}
      // To make flexbox and truncated text play nice together
      // (https://css-tricks.com/flexbox-truncated-text/).
      style={{ minWidth: 0 }}
    >
      {isLoading ? (
        <div
          className={"d-flex h-100 justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <div className={"d-flex flex-column h-100"}>
          <NavbarMobile />
          <div className={"d-flex h-100"}>
            {isScreenLg ? (
              // Navbar component for desktop (i.e. screens LG and larger).
              // XXX (Rob): Couldn't split this out to a separate component
              // because of it breaking the transition effect.
              <div
                style={
                  isNavExpanded
                    ? {
                        width: "15rem",
                        minWidth: "15rem",
                        transition: "width 0.25s, min-width 0.25s",
                      }
                    : {
                        width: "4.25rem",
                        minWidth: "4.25rem",
                        transition: "width 0.25s, min-width 0.25s",
                      }
                }
                className={
                  "pt-3 pb-3 px-3 bg-light d-flex flex-column justify-content-between border-end border-1 border-light-subtle"
                }
              >
                <Nav
                  variant="pills"
                  justify={true}
                  fill={true}
                  navbar={true}
                  navbarScroll={true}
                  className={"d-flex flex-column nav-dark h-100"}
                >
                  <div
                    className={
                      "d-flex align-items-center justify-content-between"
                    }
                    style={{ marginBottom: "0.7rem" }}
                  >
                    {isNavExpanded ? (
                      <Link to={"/dashboard/chats"}>
                        <img
                          alt="Logo"
                          title={"Logo"}
                          src={
                            organizationData && organizationData.logoUrl
                              ? organizationData.logoUrl
                              : logo
                          }
                          height="32"
                          className={"overflow-hidden"}
                        />
                      </Link>
                    ) : (
                      <span></span>
                    )}
                    <Button
                      title={
                        isNavExpanded ? "Menu inklappen" : "Menu uitklappen"
                      }
                      size={"sm"}
                      variant="light"
                      type="button"
                      onClick={() => setIsNavExpanded(!isNavExpanded)}
                      className={
                        "rounded-pill py-0 text-dark border-1 border-light"
                      }
                      style={
                        isNavExpanded
                          ? { marginRight: "initial" }
                          : { marginRight: "0.1rem" }
                      }
                    >
                      <span className={"d-flex align-items-center py-2"}>
                        <List />
                      </span>
                    </Button>
                  </div>
                  <div
                    className={"d-flex flex-column flex-grow-1"}
                    style={{ maxWidth: "100%" }}
                  >
                    <div className={"flex-grow-1"}>
                      <Nav.Item className={"mb-2"}>
                        <LinkContainer to="/dashboard/chats">
                          <Nav.Link
                            eventKey={"chats"}
                            title={"Chats"}
                            className={"px-2 rounded-3"}
                            active={pathName.includes("chats")}
                          >
                            <div
                              className={
                                "d-flex align-items-center justify-content-start"
                              }
                            >
                              <Chat
                                className={"my-1"}
                                style={{ minWidth: "1rem" }}
                              />
                              {isNavExpanded ? (
                                <span className={"ms-2 my-0"}>Chats</span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Nav.Link>
                        </LinkContainer>
                      </Nav.Item>
                      {isNavExpanded ? <MostRecentChats /> : <></>}
                    </div>
                    <div>
                      <Nav.Item className={"mb-2"}>
                        <LinkContainer to="/dashboard/documents">
                          <Nav.Link
                            eventKey={"documents"}
                            title={"Documenten"}
                            className={"px-2 rounded-3"}
                            active={pathName.includes("documents")}
                          >
                            <div
                              className={
                                "d-flex align-items-center justify-content-start"
                              }
                            >
                              <FileEarmark
                                className={"my-1"}
                                style={{ minWidth: "1rem" }}
                              />
                              {isNavExpanded ? (
                                <span className={"ms-2 my-0"}>Documenten</span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Nav.Link>
                        </LinkContainer>
                      </Nav.Item>
                      {isAdmin ? (
                        <>
                          <Nav.Item className={"mb-2"}>
                            <LinkContainer to="/dashboard/users">
                              <Nav.Link
                                eventKey={"users"}
                                title={"Gebruikers"}
                                className={"px-2 rounded-3"}
                                active={pathName.includes("users")}
                              >
                                <div
                                  className={
                                    "d-flex align-items-center justify-content-start"
                                  }
                                >
                                  <People
                                    className={"my-1"}
                                    style={{ minWidth: "1rem" }}
                                  />
                                  {isNavExpanded ? (
                                    <span className={"ms-2 my-0"}>
                                      Gebruikers
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Nav.Link>
                            </LinkContainer>
                          </Nav.Item>
                          <Nav.Item className={"mb-2"}>
                            <LinkContainer to="/dashboard/widget-instructions">
                              <Nav.Link
                                eventKey={"widget-instructions"}
                                title={"Widget"}
                                className={"px-2 rounded-3"}
                                active={pathName.includes(
                                  "widget-instructions",
                                )}
                              >
                                <div
                                  className={
                                    "d-flex align-items-center justify-content-start"
                                  }
                                >
                                  <Window
                                    className={"my-1"}
                                    style={{ minWidth: "1rem" }}
                                  />
                                  {isNavExpanded ? (
                                    <span className={"ms-2 my-0"}>Widget</span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Nav.Link>
                            </LinkContainer>
                          </Nav.Item>
                        </>
                      ) : (
                        <></>
                      )}
                      <Nav.Item>
                        <LinkContainer to="/dashboard/account">
                          <Nav.Link
                            eventKey={"account"}
                            title={"Account"}
                            className={"px-2 rounded-3"}
                            active={pathName.includes("account")}
                          >
                            <div
                              className={
                                "d-flex align-items-center justify-content-start"
                              }
                            >
                              <Person
                                className={"my-1"}
                                style={{ minWidth: "1rem" }}
                              />
                              {isNavExpanded ? (
                                <span className={"ms-2 my-0"}>Account</span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Nav.Link>
                        </LinkContainer>
                      </Nav.Item>
                    </div>
                  </div>
                </Nav>
              </div>
            ) : (
              <></>
            )}
            <div
              className={"pt-3 pb-3 bg-white flex-grow-1 px-3"}
              // To make flexbox and truncated text play nice together
              // (https://css-tricks.com/flexbox-truncated-text/).
              style={{ minWidth: 0 }}
            >
              <Routes>
                <Route path="*" element={<Navigate to="chats" />} />
                <Route
                  path="chats/*"
                  element={
                    <Routes>
                      <Route path="*" element={<ChatsList />} />
                      <Route
                        path="chat/*"
                        element={
                          <Routes>
                            <Route path="*" element={<ChatsDetail />} />
                            <Route
                              path="citation"
                              element={<ChatsDetailCitation />}
                            />
                          </Routes>
                        }
                      />
                    </Routes>
                  }
                />
                <Route
                  path="documents/*"
                  element={
                    <Routes>
                      <Route path="*" element={<DocumentsList />} />
                      <Route path="document" element={<DocumentsDetail />} />
                      <Route path="upload" element={<DocumentsAdd />} />
                    </Routes>
                  }
                />
                <Route path="users" element={<UsersList />} />
                <Route
                  path="widget-instructions"
                  element={<WidgetInstructions />}
                />
                <Route path="account" element={<Account />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
