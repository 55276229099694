import "react-pdf/dist/Page/AnnotationLayer.css";

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";

import { getDocumentBlobUrl } from "../../../common/api/Utils";
import { DocumentViewer } from "../../../common/document-viewer/DocumentViewer";
import { useAppSelector } from "../../../../hooks";
import { DocumentDownload } from "../../../common/document-viewer/DocumentDownloadButton";

export default function DocumentsDetail(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const documentData: IUploadedDocumentRowData = location?.state?.document;
  const [documentBlobUrl, setDocumentBlobUrl] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsLoading(true);
    if (!documentData?.url) {
      // If no document data, redirect to documents overview
      // (this is probably caused by a user copy-pasting the URL).
      return navigate("/dashboard/documents");
    } else {
      getDocumentBlobUrl(documentData).then((documentBlobUrl: string) => {
        setDocumentBlobUrl(documentBlobUrl);
        setIsLoading(false);
      });
    }
  }, [navigate, documentData]);

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div
        className={"d-flex align-items-center mb-3 py-1 text-muted"}
        // To make space for the document download button.
        style={isScreenLg ? { marginRight: "15rem" } : {}}
      >
        <Button
          title={"Vorige"}
          name={"Vorige"}
          type="button"
          onClick={() => navigate("/dashboard/documents")}
          className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
        >
          <ArrowLeft />
        </Button>
        <h6
          onClick={() => navigate("/dashboard/documents")}
          className={"m-0 ms-1 text-decoration-underline"}
          style={{ cursor: "pointer" }}
        >
          Documenten
        </h6>
        <h6 className={"m-0 mx-2"}>/</h6>
        <h6 className={"m-0 text-truncate"}>
          {isScreenLg ? documentData?.name : "Document"}
        </h6>
        <DocumentDownload
          document={documentData}
          documentBlobUrl={documentBlobUrl}
          isLoading={isLoading}
        />
      </div>
      {isLoading ? (
        <div
          className={"h-100 d-flex justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <DocumentViewer
          document={documentData}
          documentBlobUrl={documentBlobUrl}
          documentPageIndex={1}
        />
      )}
    </div>
  );
}
