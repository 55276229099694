import React from "react";
import { Button } from "react-bootstrap";
import { CloudArrowDown } from "react-bootstrap-icons";

import { useAppSelector } from "../../../hooks";
import { handleDownload } from "../api/Utils";

export const DocumentDownload = (props: {
  document: IDocument;
  documentBlobUrl: string;
  isLoading: boolean;
}): React.JSX.Element => {
  /**
   * Document download button component.
   */
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  return (
    <Button
      title={"Document downloaden"}
      variant="dark"
      type="button"
      onClick={() => handleDownload(props.document, props.documentBlobUrl)}
      disabled={props.isLoading}
      className={
        "d-flex align-items-center position-absolute end-0 rounded-pill px-3 me-3"
      }
    >
      {isScreenLg ? <CloudArrowDown className={"me-2"} /> : <></>}
      {isScreenLg ? "Document downloaden" : "Download"}
    </Button>
  );
};
