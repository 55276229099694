import React from "react";
import { Button, Card, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { PlusLg, CloudArrowUp, XLg, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { Api } from "../../../common/api/Api";
import { useAppSelector } from "../../../../hooks";
import { getFileHash } from "../../../common/Utils";

export default function DocumentsAdd(): React.JSX.Element {
  /**
   * Select and upload documents to the Manual server.
   */

  const navigate = useNavigate();

  const inputFieldFilesRef = React.useRef<HTMLInputElement>(null);
  const inputFieldDirectoryRef = React.useRef<HTMLInputElement>(null);
  // Reference to the grid, so we can access its API methods.
  const gridRef = React.useRef<AgGridReact<IDocumentRowData>>(null);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [documentFileHashData, setDocumentFileHashData] = React.useState<
    IDocumentFileHashData[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isShowingLoadingOverlay, setIsShowingLoadingOverlay] =
    React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [errorCount, setErrorCount] = React.useState<number>(0);
  const [duplicateCount, setDuplicateCount] = React.useState<number>(0);
  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);
  const [showDocumentSelectionModal, setShowDocumentSelectionModal] =
    React.useState(false);

  const supportedDocumentTypes: string[] = [
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleDocumentDelete = (currentRowData: IDocumentRowData): void => {
    /**
     * Delete the document from the row data.
     */
    setRowData(
      rowData.filter((item) => !(item.fileHash === currentRowData.fileHash)),
    );
  };

  const DeleteButtonComponent = (props: { data: IDocumentRowData }) => {
    const _currentRowData: IDocumentRowData = props.data;
    return (
      <Button
        title={"Document deselecteren"}
        variant="light"
        type="button"
        size={"sm"}
        className={"rounded-pill text-dark mb-1 py-1"}
        onClick={() => handleDocumentDelete(_currentRowData)}
        disabled={isUploading || isShowingLoadingOverlay}
      >
        <span className={"d-flex align-items-center py-1"}>
          <XLg />
        </span>
      </Button>
    );
  };

  const DocumentSelectionTextComponent = () => {
    /**
     * Text component for the document selection method (files or directory).
     */
    return (
      <>
        <span
          className={"m-0 text-decoration-underline text-truncate"}
          style={{ cursor: "pointer" }}
          onClick={() => inputFieldFilesRef.current?.click()}
        >
          documenten
        </span>{" "}
        of{" "}
        <span
          className={"m-0 text-decoration-underline text-truncate"}
          style={{ cursor: "pointer" }}
          onClick={() => inputFieldDirectoryRef.current?.click()}
        >
          een map
        </span>
      </>
    );
  };

  const DocumentSelectionModalComponent = () => {
    return (
      <Modal
        centered={true}
        show={showDocumentSelectionModal}
        onHide={() => setShowDocumentSelectionModal(false)}
        contentClassName={"bg-white rounded-3 border-1 border-light-subtle"}
      >
        <Modal.Header closeButton={true} className={"p-3 border-0"}>
          <Modal.Title>
            <h5 className={"mb-0"}>Meer toevoegen</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3 border-0"}>
          <p className={"m-0"}>
            Selecteer <DocumentSelectionTextComponent />.
          </p>
        </Modal.Body>
      </Modal>
    );
  };

  const columnDefs = [
    {
      field: "name",
      headerName: "Naam",
      flex: 8,
      cellClass: "text-truncate",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        filterPlaceholder: "Zoek in documenten...",
        filterOptions: ["contains"],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
    },
    { field: "extension", headerName: "Type", flex: 2 },
    // {field: "size", headerName: "Grootte (bytes)", flex: 2},
    {
      field: "actions",
      headerName: "Acties",
      flex: isScreenLg ? 2 : 3,
      cellRenderer: DeleteButtonComponent,
    },
  ];
  const [rowData, setRowData] = React.useState<IDocumentRowData[]>([]);

  const getDocumentFileHashData = async () => {
    await Api.get("/documents/file-hashes").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const documentFileHashData: IDocumentFileHashData[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.forEach((_documentFileHashData: any) => {
          documentFileHashData.push({
            id: _documentFileHashData.id,
            fileHash: _documentFileHashData.file_hash,
          });
        });
        setDocumentFileHashData(documentFileHashData);
      },
    );
  };

  React.useEffect(() => {
    /**
     * Get the document file hash data.
     */
    setIsLoading(true);
    getDocumentFileHashData().then(() => {
      setIsLoading(false);
    });
  }, []);

  React.useEffect(() => {
    /**
     * Initialize the inputFieldDirectoryRef.
     * This is done here because React won't allow us to do it in the HTML input element.
     */
    inputFieldDirectoryRef.current?.setAttribute("webkitdirectory", "");
    inputFieldDirectoryRef.current?.setAttribute("mozdirectory", "");
    inputFieldDirectoryRef.current?.setAttribute("directory", "");
  }, [inputFieldDirectoryRef.current]);

  React.useEffect(() => {
    /**
     * Hide certain columns on smaller screens.
     */
    const columnsToHide: string[] = ["extension"];
    if (isGridReady) {
      if (isScreenLg) {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, true);
      } else {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, false);
      }
    }
  }, [isGridReady, isScreenLg]);

  const handleDocumentAdd = async (document: File): Promise<void> => {
    /**
     * Add document to the row data, if the document type is supported.
     * Format the document name (lowercase the extension).
     */
    if (supportedDocumentTypes.includes(document.type)) {
      // Get the SHA-256 hash of the document file, using SubtleCrypto: digest() method.
      await getFileHash(document).then((fileHash: string) => {
        // Format the document name (lowercase extension).
        const documentName: string = document.name.split(".").shift()!;
        const documentExtension: string = document.name
          .split(".")
          .pop()!
          .toLowerCase();
        const documentNameFormatted: string = `${documentName}.${documentExtension}`;
        // Prepare the row data for the document.
        const _currentRowData: IDocumentRowData = {
          name: documentNameFormatted,
          extension: documentExtension,
          processStatus: "submitted",
          size: document.size,
          fileHash: fileHash,
          // Keep the source document, for uploading (also with the name formatted).
          document: new File([document], documentNameFormatted, {
            type: document.type,
          }),
        };
        // Get the realtime row data here, to avoid false empty row data.
        const realtimeRowData: IDocumentRowData[] =
          gridRef.current?.api.getGridOption("rowData") || rowData;
        // Check that document isn't already in the row data.
        if (
          !realtimeRowData.find(
            (item) => item.fileHash === _currentRowData.fileHash,
          )
        ) {
          // Add the document to the row data (parsed as row data and also keeping the source document).
          setRowData((_rowData) => [..._rowData, _currentRowData]);
        }
      });
    }
    setShowDocumentSelectionModal(false);
  };

  const handleDocumentsChange = async (
    event: React.ChangeEvent | React.BaseSyntheticEvent,
  ): Promise<void> => {
    /**
     * Handle the documents input field change event.
     */
    const newDocuments: FileList = event.target.files;
    // Add documents to the row data.
    for (const document of Array.from(newDocuments)) {
      await handleDocumentAdd(document);
    }
    // Reset the input field.
    event.target.value = null;
  };

  const handleOnDrop = async (event: React.DragEvent): Promise<void> => {
    /**
     * Handle the drag & drop's onDrop event.
     *
     * Based on the HTML Drag and Drop API
     * (https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API)
     * using the DataTransferItemList interface
     * (https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItemList).
     */
    event.preventDefault();
    removeDragAndDropVisualIndication(event);

    const _handleEntry = async (entry: FileSystemEntry) => {
      /**
       * Handle the dropped entry (document or directory).
       * If the entry is a directory, to handle nested directories recursively.
       */
      if (entry.isFile) {
        const documentEntry = entry as FileSystemFileEntry;
        // Add document to the row data.
        documentEntry.file(
          async (document) => await handleDocumentAdd(document),
        );
      } else if (entry.isDirectory) {
        const directoryEntry = entry as FileSystemDirectoryEntry;
        directoryEntry.createReader().readEntries((innerEntries) => {
          for (const innerEntry of innerEntries) {
            _handleEntry(innerEntry);
          }
        });
      }
    };

    // Loop through the dropped items (documents or directories) and handle each entry.
    Array.from(event.dataTransfer.items).forEach((item: DataTransferItem) => {
      /**
       * Cater for future getAsEntry() method availability
       * (see https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry).
       */
      // @ts-expect-error:next-line
      if (typeof item["getAsEntry"] === "function") {
        // @ts-expect-error:next-line
        _handleEntry(item["getAsEntry"]()!);
      } else if (typeof item["webkitGetAsEntry"] === "function") {
        _handleEntry(item["webkitGetAsEntry"]()!);
      } else {
        console.error(
          "Both getAsEntry() and webkitGetAsEntry() methods not available.",
        );
      }
    });
  };

  const addDragAndDropVisualIndication = (event: React.DragEvent): void => {
    /**
     * Show a visual indication (lightblue border) for drag and drop.
     */
    event.preventDefault();
    const agGrid = document.getElementsByClassName("ag-theme-quartz")[0];
    if (agGrid! && !agGrid.classList.contains("dragging-and-dropping")) {
      agGrid?.classList.add("dragging-and-dropping");
    }
  };

  const removeDragAndDropVisualIndication = (event: React.DragEvent): void => {
    /**
     * Remove the visual indication (lightblue border) for drag and drop.
     */
    event.preventDefault();
    const agGrid = document.getElementsByClassName("ag-theme-quartz")[0];
    if (agGrid! && agGrid.classList.contains("dragging-and-dropping")) {
      agGrid?.classList.remove("dragging-and-dropping");
    }
  };

  const handleUpload = async (): Promise<void> => {
    /**
     * Prepare and upload documents. Process each document individually.
     */
    // Show the loading overlay.
    gridRef.current!.api.showLoadingOverlay();
    setIsShowingLoadingOverlay(true);
    setIsUploading(true);
    setErrorCount(0);
    setDuplicateCount(0);
    // Loop through the row data and try to upload each document.
    for (let i = 0; i < rowData.length; i++) {
      // Calculate the progress.
      const progress: number = 100 * ((i + 1) / rowData.length);
      setProgress(progress);
      // Skip the document if it's already uploaded.
      if (
        documentFileHashData.find(
          (item) => item.fileHash === rowData[i].fileHash,
        ) !== undefined
      ) {
        // Update the document's name with the duplicate indication in the grid.
        const _duplicate_message: string = "(Bestaat al)";
        const _currentRowData: IDocumentRowData = rowData[i];
        _currentRowData.name = _currentRowData.name.replace(
          _duplicate_message,
          "",
        );
        _currentRowData.name = `${_duplicate_message} ${_currentRowData.name}`;
        setDuplicateCount(
          (previousDuplicateCount) => previousDuplicateCount + 1,
        );
        continue;
      }
      // Upload the document.
      const document: File = rowData[i].document;
      const data: FormData = new FormData();
      data.append("file", document);
      await Api.post("documents/", data).then(
        () => {
          // TODO (Rob): Remove the document from the grid?
        },
        () => {
          // Update the document's name with the error in the grid.
          const _error_message: string = "(Kan niet verwerkt worden)";
          const _currentRowData: IDocumentRowData = rowData[i];
          _currentRowData.name = _currentRowData.name.replace(
            _error_message,
            "",
          );
          _currentRowData.name = `${_error_message} ${_currentRowData.name}`;
          setErrorCount((previousErrorCount) => previousErrorCount + 1);
        },
      );
    }
    setIsUploading(false);
  };

  const NoRowsOverlayComponent = () => {
    /**
     * Component shown when there are no documents selected.
     */
    return (
      <div style={{ pointerEvents: "auto" }} className={"m-3 mt-4"}>
        <CloudArrowUp size={38} className={"text-muted"} />
        <p className={"m-0 mt-3 mb-3"}>
          Drag & drop documenten hier, of
          <br />
          selecteer <DocumentSelectionTextComponent />.
        </p>
        <small className={"text-muted mt-"}>
          Ondersteunde documenttypen: pdf, pptx, docx, xlsx, txt.
        </small>
      </div>
    );
  };

  const LoadingOverlayComponent = (props: {
    isUploading: boolean;
    progress: number;
    errorCount: number;
    duplicateCount: number;
  }) => {
    const _closeOverlay = () => {
      gridRef.current!.api.hideOverlay();
      setIsShowingLoadingOverlay(false);
      setRowData([]);
      setProgress(0);
      navigate("/dashboard/documents");
    };
    return (
      <div style={{ minWidth: "18rem", maxWidth: "25rem" }}>
        <Card className={"bg-white rounded-3 border-1"}>
          <Card.Header className={"p-3 text-start rounded-3 border-0 bg-white"}>
            <Card.Title className={"mb-0"}>
              <h5 className={"mb-0"}>Documenten uploaden</h5>
            </Card.Title>
          </Card.Header>
          <Card.Body className={"p-3 text-start border-0"}>
            <p className={"m-0"}>
              {props.isUploading ? (
                <span>Documenten worden geupload...</span>
              ) : (
                <span>
                  Documenten zijn geupload.
                  {props.duplicateCount === 1 ? (
                    <>
                      <br />
                      <br />1 document bestond al en is overgeslagen.
                    </>
                  ) : props.duplicateCount > 1 ? (
                    <>
                      <br />
                      <br />
                      {props.duplicateCount} documenten bestonden al en zijn
                      overgeslagen.
                    </>
                  ) : (
                    ""
                  )}
                  {props.errorCount === 1 ? (
                    <>
                      <br />
                      <br />1 document kon niet verwerkt worden en is
                      overgeslagen.
                    </>
                  ) : props.errorCount > 1 ? (
                    <>
                      <br />
                      <br />
                      {props.errorCount} documenten konden niet verwerkt worden
                      en zijn overgeslagen.
                    </>
                  ) : (
                    ""
                  )}
                </span>
              )}
            </p>
          </Card.Body>
          <Card.Footer
            className={"rounded-bottom-3 border-0 p-3 bg-white"}
            style={{ minHeight: "3.125rem" }}
          >
            {props.isUploading ? (
              <div>
                <ProgressBar
                  min={0}
                  now={props.progress}
                  max={100}
                  animated={true}
                  variant={"dark"}
                  className={"my-2 rounded-3"}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  title={"Ok"}
                  variant="dark"
                  className={"rounded-3"}
                  disabled={props.isUploading}
                  onClick={_closeOverlay}
                >
                  Ok
                </Button>
              </div>
            )}
          </Card.Footer>
        </Card>
      </div>
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <Button
          title={"Vorige"}
          name={"Vorige"}
          type="button"
          onClick={() => navigate(-1)}
          className={"d-flex me-2 border-0 bg-transparent text-muted p-0"}
        >
          <ArrowLeft />
        </Button>
        <h6
          onClick={() => navigate("/dashboard/documents")}
          className={"m-0 ms-1 text-decoration-underline"}
          style={{ cursor: "pointer" }}
        >
          Documenten
        </h6>
        <h6 className={"m-0 mx-2"}>/</h6>
        <h6 className={"m-0 text-truncate"}>Upload</h6>
      </div>

      {isLoading ? (
        <div
          className={"h-100 d-flex justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <>
          <DocumentSelectionModalComponent />
          <input
            ref={inputFieldFilesRef}
            name="files"
            type="file"
            multiple={true}
            accept={supportedDocumentTypes.join(",")}
            onChange={handleDocumentsChange}
            hidden={true}
          />
          <input
            ref={inputFieldDirectoryRef}
            name="directory"
            type="file"
            multiple={true}
            accept={supportedDocumentTypes.join(",")}
            onChange={handleDocumentsChange}
            hidden={true}
          />

          <div
            className="ag-theme-quartz h-100"
            style={{ minHeight: "15rem" }}
            onDrop={(e) => handleOnDrop(e)}
            onDragOver={(e) => addDragAndDropVisualIndication(e)}
            onDragLeave={(e) => removeDragAndDropVisualIndication(e)}
          >
            <AgGridReact<IDocumentRowData>
              ref={gridRef}
              onGridReady={() => setIsGridReady(true)}
              // @ts-expect-error:next-line
              columnDefs={columnDefs}
              rowData={rowData}
              reactiveCustomComponents={true}
              noRowsOverlayComponent={NoRowsOverlayComponent}
              loadingOverlayComponent={LoadingOverlayComponent}
              loadingOverlayComponentParams={{
                progress: progress,
                isUploading: isUploading,
                errorCount: errorCount,
                duplicateCount: duplicateCount,
              }}
              suppressCellFocus={true}
            />
          </div>

          {rowData.length > 0 ? (
            <div className={"d-flex justify-content-between mt-3"}>
              <div className={"rounded-2 bg-white"}>
                <Button
                  title={"Meer documenten toevoegen"}
                  variant="light"
                  type="button"
                  onClick={() => setShowDocumentSelectionModal(true)}
                  className={
                    "d-flex align-items-center rounded-pill border-1 border-light-subtle px-3"
                  }
                  disabled={isUploading || isShowingLoadingOverlay}
                >
                  <PlusLg className={"me-2"} />
                  Meer toevoegen
                </Button>
              </div>

              <Button
                title={"Documenten uploaden"}
                variant="success"
                type="button"
                disabled={
                  rowData.length === 0 || isUploading || isShowingLoadingOverlay
                }
                onClick={handleUpload}
                className={"d-flex align-items-center rounded-pill px-3"}
              >
                <CloudArrowUp className={"me-2"} />
                {isScreenLg ? "Documenten uploaden" : "Upload"}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}
