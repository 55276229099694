import React, { FormEvent } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Send, PlusLg } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { isApiError as _isApiError } from "../../../common/api/Utils";

import { Api } from "../../../common/api/Api";

export default function UsersAdd(): React.JSX.Element {
  /**
   * Select and invite users to the organization.
   */
  const navigate = useNavigate();

  const [formDataEmail, setFormDataEmail] = React.useState<string>("");
  const [formDataIsAdmin, setFormDataIsAdmin] = React.useState<boolean>(false);
  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setFormDataEmail("");
    setFormDataIsAdmin(false);
    setShowSuccessPage(false);
    setApiError(undefined);
    // "Navigate" to the users list (we're already on that screen),
    // to refresh the list of invitations.
    navigate("/dashboard/users");
  };
  const handleError = (error: IApiError | undefined) => {
    if (error?.errorMessage) {
      return (
        <Form.Text className="text-danger">{error.errorMessage}</Form.Text>
      );
    }
    return <></>;
  };

  const submitForm = async (e: FormEvent): Promise<void> => {
    /**
     * Invite a user via email.
     */
    e.preventDefault();
    // Clear previous errors and success messages, if any.
    setApiError(undefined);
    // Invite the user.
    await Api.post("invitations/send-invite/", [
      { email: formDataEmail, is_for_admin: formDataIsAdmin },
    ]).then(
      (response) => {
        if (_isApiError(response)) {
          setApiError(response);
        } else {
          if (response?.invitees?.includes(formDataEmail)) {
            setShowSuccessPage(true);
          } else {
            setApiError({
              errorMessage: "Er is een fout opgetreden. Probeer het opnieuw.",
            });
          }
        }
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  return (
    <>
      <Button
        title={"Nieuwe gebruiker"}
        variant="success"
        type="button"
        onClick={handleShow}
        className={
          "d-flex align-items-center position-absolute end-0 rounded-pill px-3 me-3"
        }
      >
        <PlusLg className={"me-2"} />
        Nieuwe gebruiker
      </Button>

      <Modal
        centered={true}
        show={showModal}
        onHide={handleClose}
        contentClassName={"bg-white rounded-3 border-1 border-light-subtle"}
      >
        <Modal.Header closeButton={true} className={"p-3 border-0"}>
          <Modal.Title>
            <h5 className={"mb-0"}>Gebruiker uitnodigen</h5>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body className={"p-3 border-0"}>
            {showSuccessPage ? (
              <p className={"m-0"}>
                Een uitnodiging is verstuurd naar <i>{formDataEmail}</i>.
                Wanneer de uitnodiging is geaccepteerd, wordt{" "}
                <i>{formDataEmail}</i> toegevoegd aan de lijst van gebruikers.
                <br />
                <br />
                <small>De uitnodiging is 31 dagen geldig.</small>
              </p>
            ) : (
              <>
                <p className={"mb-3"}>
                  Vul hier de email in van de nieuwe gebruiker om deze een
                  uitnodiging te sturen.
                </p>
                <Form.Group className="mb-2">
                  <Form.Label
                    column={false}
                    className={"small text-muted mb-1"}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={formDataEmail}
                    onChange={(e) => setFormDataEmail(e.target.value)}
                    required={true}
                    autoFocus={true}
                    className={
                      "rounded-pill py-2 px-3 border-2 border-light-subtle"
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Check
                    type={"checkbox"}
                    id={"is-admin-checkbox"}
                    label={"Deze gebruiker is een beheerder."}
                    onChange={(e) => setFormDataIsAdmin(e.target.checked)}
                    className={"mt-4"}
                  />
                </Form.Group>
                {handleError(apiError)}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className={"p-3 border-0"}>
            {showSuccessPage ? (
              <Button
                title={"Nog een gebruiker uitnodigen"}
                variant="dark"
                type="button"
                onClick={() => {
                  setFormDataEmail("");
                  setFormDataIsAdmin(false);
                  setShowSuccessPage(false);
                }}
                className={"rounded-3 border-1 border-light-subtle m-0"}
              >
                Nog een gebruiker uitnodigen
              </Button>
            ) : (
              <Button
                title={"Uitnodiging versturen"}
                variant="success"
                type="submit"
                disabled={!formDataEmail}
                className={
                  "d-flex align-items-center rounded-3 border-1 border-light-subtle m-0"
                }
              >
                <Send className={"me-2"} />
                Uitnodiging versturen
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
