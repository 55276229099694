import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import {
  CircleFill,
  CheckCircleFill,
  ThreeDotsVertical,
  Trash,
} from "react-bootstrap-icons";
import { ValueFormatterParams } from "ag-grid-community";
import { useLocation } from "react-router-dom";

import { Api } from "../../../common/api/Api";
import { isoStringToDate, dateToString } from "../../../common/Utils";
import ErrorAlert from "../../../common/ErrorAlert";
import UsersAdd from "./UsersAdd";
import { hasAdminRights } from "../../../common/api/Auth";
import { MAX_ITEMS_WITHOUT_PAGINATION } from "../../../../constants";
import { useAppSelector } from "../../../../hooks";

const dateTimeFormatter = (
  params: ValueFormatterParams,
): string | undefined => {
  if (params.value) {
    return dateToString(params.value);
  }
};

const InvitationsList = (): React.JSX.Element => {
  const location = useLocation();
  const gridRef = React.useRef<AgGridReact<IInvitation>>(null);
  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);
  const [rowData, setRowData] = React.useState<IInvitation[]>([]);
  const actionsCellRenderer = (props: { data: IInvitation }) => {
    // no clue why eslint keeps complaining about "data is missing in props validation"
    // eslint-disable-next-line react/prop-types
    const id = props.data.id;

    const deleteInvitation = async () => {
      await Api.delete(`invites/${id}/`).then(
        () => {
          const rowNode = gridRef.current?.api.getRowNode(id);
          if (rowNode?.data) {
            gridRef.current?.api.applyTransaction({ remove: [rowNode.data] });
          }
        },
        (error: IApiError) => {
          console.log(error);
        },
      );
    };
    return (
      <Dropdown className={"position-absolute"} drop={"start"}>
        <Dropdown.Toggle
          title={"Acties"}
          variant="light"
          size={"sm"}
          id="dropdown-basic"
          className={
            "dropdown-toggle-no-arrow-icon rounded-pill text-dark mb-1 py-1"
          }
        >
          <span className={"d-flex align-items-center py-1"}>
            <ThreeDotsVertical />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={"mx-1 rounded-3 px-3"}>
          <Dropdown.Item className={"bg-white px-0"}>
            <Button
              title={"Verwijderen"}
              variant="light"
              size={"sm"}
              className={
                "d-flex align-items-center w-100 rounded-3 border-1 border-light-subtle"
              }
              onClick={deleteInvitation}
            >
              <Trash className={"me-2"} />
              Verwijderen
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const NoRowsOverlayComponent = (props: { isLoading: boolean }) => {
    return (
      <div style={{ pointerEvents: "auto" }}>
        {props.isLoading ? (
          <Spinner animation="border" variant="secondary" />
        ) : (
          <p>Geen uitnodigingen.</p>
        )}
      </div>
    );
  };

  const getInvitationsData = async () => {
    await Api.getPaginated("invites/").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const invitations: IInvitation[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.forEach((invitationData: any) => {
          invitations.push({
            id: invitationData.id,
            email: invitationData.email,
            invited_at: isoStringToDate(invitationData.invited_at),
            expires_at: isoStringToDate(invitationData.expires_at),
            partially_accepted: invitationData.partially_accepted,
          });
        });
        setRowData(invitations);
      },
      (error: IApiError) => {
        console.log(error);
      },
    );
  };

  React.useEffect(() => {
    setIsLoading(true);
    hasAdminRights().then(() => {
      getInvitationsData().then(() => {
        setIsLoading(false);
      });
    });
  }, [location]);

  React.useEffect(() => {
    /**
     * Hide certain columns on smaller screens.
     */
    const columnsToHide: string[] = [
      "invited_at",
      "expires_at",
      "partially_accepted",
    ];
    if (isGridReady) {
      if (isScreenLg) {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, true);
      } else {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, false);
      }
    }
  }, [isGridReady, isScreenLg]);

  const columnDefs = [
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        filterPlaceholder: "Zoek in uitnodigingen...",
        filterOptions: ["contains"],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      flex: 6,
      cellClass: "text-truncate",
    },
    {
      field: "invited_at",
      headerName: "Verstuurd",
      sort: "desc",
      valueFormatter: dateTimeFormatter,
      flex: 3,
    },
    {
      field: "expires_at",
      headerName: "Verloopt",
      valueFormatter: dateTimeFormatter,
      flex: 3,
    },
    {
      field: "partially_accepted",
      headerName: "Commentaar",
      valueFormatter: (params: ValueFormatterParams): string | undefined => {
        if (params.value) {
          return "Deze uitnodiging is geaccepteerd, maar de gebruiker heeft geen account aangemaakt.";
        } else {
          return "";
        }
      },
      flex: 3,
      cellDataType: "text",
      wrapText: true,
      autoHeight: true,
      cellClass: "lh-sm",
    },
    {
      field: "actions",
      headerName: "Acties",
      flex: 2,
      cellRenderer: actionsCellRenderer,
      wrapText: true,
      autoHeight: true,
    },
  ];

  // Return nothing if there is nothing to show
  if (rowData.length === 0) {
    return <></>;
  }

  return (
    <>
      <h5>Openstaande uitnodigingen</h5>
      <div className="ag-theme-quartz h-100" style={{ minHeight: "10rem" }}>
        <AgGridReact<IInvitation>
          ref={gridRef}
          onGridReady={() => setIsGridReady(true)}
          // @ts-expect-error:next-line
          columnDefs={columnDefs}
          rowData={rowData}
          reactiveCustomComponents={true}
          noRowsOverlayComponent={NoRowsOverlayComponent}
          rowClass={"ag-row-focused-on-top"}
          noRowsOverlayComponentParams={{ isLoading: isLoading }}
          suppressCellFocus={true}
          pagination={rowData.length > MAX_ITEMS_WITHOUT_PAGINATION}
          getRowId={(params) => params.data.id}
        />
      </div>
    </>
  );
};

export default function UsersList(): React.JSX.Element {
  // Reference to the grid, so we can access its API methods.
  const gridRef = React.useRef<AgGridReact<IUserRowData>>(null);
  const isScreenLg: boolean = useAppSelector((state) => state.isScreenLg.value);

  const [apiError, setApiError] = React.useState<IApiError | undefined>();
  const [isGridReady, setIsGridReady] = React.useState<boolean>(false);

  const HasAdminRightsComponent = (props: { data: IUserRowData }) => {
    const _currentRowData: IUserRowData = props.data;
    if (_currentRowData.hasAdminRights) {
      return (
        <span
          className={"text-success"}
          title={"Gebruiker heeft beheerdersrechten"}
        >
          <CheckCircleFill />
        </span>
      );
    } else {
      return (
        <span
          className={"text-body-tertiary"}
          title={"Gebruiker heeft geen beheerdersrechten"}
        >
          <CircleFill />
        </span>
      );
    }
  };

  const columnDefs = [
    {
      field: "email",
      headerName: "Email",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        filterPlaceholder: "Zoek in gebruikers...",
        filterOptions: ["contains"],
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      flex: 6,
      cellClass: "text-truncate",
    },
    {
      field: "hasAdminRights",
      headerName: "Beheerdersrechten",
      flex: 3,
      cellRenderer: HasAdminRightsComponent,
    },
    {
      field: "created",
      headerName: "Toegevoegd",
      sort: "desc",
      valueFormatter: dateTimeFormatter,
      flex: 3,
    },
  ];
  const [rowData, setRowData] = React.useState<IUserRowData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [enableInvite, setEnableInvite] = React.useState<boolean>(false);

  const getUsersData = async () => {
    await Api.getPaginated("users/").then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: { [key: string]: any }) => {
        const userRowData: IUserRowData[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.forEach((userData: any) => {
          userRowData.push({
            email: userData.email,
            hasAdminRights: userData.has_admin_rights,
            created: isoStringToDate(userData.created),
          });
        });
        setRowData(userRowData);
      },
      (error: IApiError) => {
        setApiError(error);
      },
    );
  };

  React.useEffect(() => {
    setIsLoading(true);
    getUsersData().then(() => {
      hasAdminRights().then((_hasAdminRights) => {
        setEnableInvite(_hasAdminRights);
        setIsLoading(false);
      });
    });
  }, []);

  React.useEffect(() => {
    /**
     * Hide certain columns on smaller screens.
     */
    const columnsToHide: string[] = ["hasAdminRights", "created"];
    if (isGridReady) {
      if (isScreenLg) {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, true);
      } else {
        gridRef.current?.api?.setColumnsVisible(columnsToHide, false);
      }
    }
  }, [isGridReady, isScreenLg]);

  const NoRowsOverlayComponent = (props: { isLoading: boolean }) => {
    return (
      <div style={{ pointerEvents: "auto" }}>
        {props.isLoading ? (
          <Spinner animation="border" variant="secondary" />
        ) : (
          <p>Geen gebruikers.</p>
        )}
      </div>
    );
  };

  return (
    <div className={"h-100 d-flex flex-column"}>
      <div className={"d-flex align-items-center mb-3 py-1 text-muted"}>
        <h6 className={"m-0"}>Gebruikers</h6>
        {enableInvite ? <UsersAdd /> : <></>}
      </div>

      {apiError ? (
        <ErrorAlert apiError={apiError} />
      ) : (
        <>
          <h5>Gebruikers</h5>
          <div className="ag-theme-quartz h-100" style={{ minHeight: "10rem" }}>
            <AgGridReact<IUserRowData>
              ref={gridRef}
              onGridReady={() => setIsGridReady(true)}
              // @ts-expect-error:next-line
              columnDefs={columnDefs}
              rowData={rowData}
              reactiveCustomComponents={true}
              noRowsOverlayComponent={NoRowsOverlayComponent}
              noRowsOverlayComponentParams={{ isLoading: isLoading }}
              suppressCellFocus={true}
              pagination={rowData.length > MAX_ITEMS_WITHOUT_PAGINATION}
            />
          </div>
          <span className={"my-2"} />
          <InvitationsList />
        </>
      )}
    </div>
  );
}
