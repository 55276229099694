import { AxiosError, AxiosResponse } from "axios";
import {
  apiErrorGeneric,
  deleteOrganizationFromRedux,
  deleteUserFromRedux,
  getAxiosInstance,
  getUserFromRedux,
  handleAxiosError,
  setOrganizationInRedux,
  setUserInRedux,
} from "./Utils";

export class Auth {
  static async auth(
    /**
     * Auth related actions (login, signup, password reset request and password reset),
     * following Allauth's headless API:
     * - Login & signup: https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Account
     * - Password reset (request & reset): https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Password-Reset
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    action: "login" | "signup" | "password/request" | "password/reset",
  ): Promise<object | IApiError> {
    const axiosInstance = await getAxiosInstance(false, true).then(
      (_axiosInstance) => {
        return _axiosInstance;
      },
    );
    const path: string = `accounts/browser/v1/auth/${action}`;
    return await axiosInstance.post(path, data).then(
      async (response: AxiosResponse) => {
        if ([200].includes(response.status)) {
          // Store user and organization in redux, if in response.
          if (response.data?.data?.user) {
            const organization: IOrganization = {
              id: response.data.data.user.organization.id,
              name: response.data.data.user.organization.name,
              logoUrl: response.data.data.user.organization.logo,
              avatarUrl: response.data.data.user.organization.avatar,
              assistantName:
                response.data.data.user.organization.assistant_name,
              assistantGreeting:
                response.data.data.user.organization.assistant_greeting,
              apiKey: response.data.data.user.organization.api_key,
            };
            await setOrganizationInRedux(organization);
            const user: IUser = {
              id: response.data.data.user.id,
              email: response.data.data.user.email,
              hasAdminRights: response.data.data.user.has_admin_rights,
            };
            await setUserInRedux(user);
          }
          return response.data;
        } else {
          return Promise.reject(apiErrorGeneric);
        }
      },
      async (error: AxiosError) => {
        if (action === "password/reset" && error.response?.status === 401) {
          // We expect to get a 401 here (i.e. an "error"),
          // according to the Allauth headless API spec.
          return error.response?.data;
        } else {
          await handleAxiosError(error);
        }
      },
    );
  }

  static async logout(): Promise<null | void> {
    /**
     * Logout following Allauth's headless API.
     * See https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Current-Session
     */
    const axiosInstance = await getAxiosInstance(false, true).then(
      (_axiosInstance) => {
        return _axiosInstance;
      },
    );
    return await axiosInstance.delete("accounts/browser/v1/auth/session").then(
      // We expect to get a 401 here (i.e. an "error"),
      // according to the Allauth headless API spec.
      null,
      async () => {
        return await deleteUserFromRedux().then(async () => {
          await deleteOrganizationFromRedux().then(() => {
            // Redirect to the login page.
            window.location.href = "/login";
          });
        });
      },
    );
  }

  static async getSession(): Promise<ISession | null> {
    /**
     * Get the current user's session, if any.
     * Following Allauth's headless API
     * (https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Current-Session).
     */
    const axiosInstance = await getAxiosInstance(false, true).then(
      (_axiosInstance) => {
        return _axiosInstance;
      },
    );
    return await axiosInstance.get("accounts/browser/v1/auth/session").then(
      async (response: AxiosResponse) => {
        if ([200].includes(response.status)) {
          const user: IUser = {
            id: response.data.data.user.id,
            email: response.data.data.user.email,
            hasAdminRights: response.data.data.user.has_admin_rights,
          };
          const organization: IOrganization = {
            id: response.data.data.user.organization.id,
            name: response.data.data.user.organization.name,
            logoUrl: response.data.data.user.organization.logo,
            avatarUrl: response.data.data.user.organization.avatar,
            assistantName: response.data.data.user.organization.assistant_name,
            assistantGreeting:
              response.data.data.user.organization.assistant_greeting,
            apiKey: response.data.data.user.organization.api_key,
          };
          const session: ISession = {
            user: user,
            organization: organization,
          };
          return session;
        } else {
          return null;
        }
      },
      async () => {
        return null;
      },
    );
  }

  static async syncSession(): Promise<void> {
    /**
     * Get the current user's session, if any, then sync with redux.
     */
    await Auth.getSession().then(async (session: ISession | null) => {
      if (session) {
        await deleteOrganizationFromRedux().then(async () => {
          await setOrganizationInRedux(session.organization);
        });
        await deleteUserFromRedux().then(async () => {
          await setUserInRedux(session.user);
        });
      }
    });
  }
}

export const isAuthenticatedIndication = async (): Promise<boolean> => {
  /**
   * An indication of the user being authenticated, without making an API call.
   */
  return await getUserFromRedux().then((_user: IUser | null) => {
    return _user ? Promise.resolve(true) : Promise.resolve(false);
  });
};

export const hasAdminRights = async (): Promise<boolean> => {
  /**
   * Check if the user has admin rights.
   */
  return await getUserFromRedux().then((_user: IUser | null) => {
    return _user ? _user.hasAdminRights : false;
  });
};
